import {Fragment, useCallback, useEffect, useState} from 'react';
// @mui
import {styled} from '@mui/material/styles';
import {
    Input,
    Slide,
    Button,
    InputAdornment,
    ClickAwayListener,
    Box,
    Autocomplete,
    TextField,
    ListItem
} from '@mui/material';
// utils
import cssStyles from '../../utils/cssStyles';
// components
import Iconify from '../../components/Iconify';
import {IconButtonAnimate} from '../../components/animate';
import RequirePermission from "../../components/component-guard/RequirePermission";

// ----------------------------------------------------------------------

type HandleSelectType = (event: any, value: { path: string } | null, reason: any, details?: any) => any;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({theme}) => ({
    ...cssStyles(theme).bgBlur(),
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: APPBAR_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up('md')]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------


export default function Searchbar() {
    const [isOpen, setOpen] = useState(false);

    const isMac = navigator.platform.indexOf("Mac") === 0 || navigator.platform === "iPhone";

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSelect: HandleSelectType = (_, value) => {
        handleClose();
    }

    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            handleClose();
        }

        if (isMac && event.key === "k" && event.metaKey) {
            handleOpen();
        } else if (!isMac && event.key === "k" && event.altKey) {
            handleOpen();
        }
    }, []);


    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, false);
        return () => window.removeEventListener('keydown', handleKeyPress, false);
    })

    return (
        <></>
        /*
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!isOpen && (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButtonAnimate onClick={handleOpen}>
                            <Iconify icon={'eva:search-fill'} width={20} height={20}/>
                        </IconButtonAnimate>
                        <Label sx={{mx: 1}}>{isMac ? '⌘' : 'Alt'} + K</Label>
                    </Box>
                )}

                <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <SearchbarStyle>
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            onChange={handleSelect}
                            renderOption={(props, option) => {
                                return (
                                    <RequirePermission permission={option.requirePermission}>
                                        <ListItem {...props} key={option.path}>
                                            <Box sx={{mr: 2}}>
                                                {(option.icon || <Iconify icon={'fe:app-menu'} width={20} height={20}/>) as any}
                                            </Box>
                                            {option.title}
                                        </ListItem>
                                    </RequirePermission>
                                );
                            }}
                            options={options}
                            getOptionLabel={(option) => option.title}
                            renderInput={params =>
                                <TextField
                                    {...params}
                                    autoFocus
                                    fullWidth
                                    placeholder="Search…"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <Iconify
                                                    icon={'eva:search-fill'}
                                                    sx={{color: 'text.disabled', width: 20, height: 20}}
                                                />
                                            </InputAdornment>,
                                        endAdornment: <></>
                                    }}
                                    sx={{mr: 1, fontWeight: 'fontWeightBold'}}
                                />}/>
                    </SearchbarStyle>
                </Slide>
            </div>
        </ClickAwayListener>*/
    );
}
