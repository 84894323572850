// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import {FormControl, FormHelperText, InputLabel, Select, SelectProps} from "@mui/material";

// ----------------------------------------------------------------------

interface IProps {
    name: string;
    label: string;
    children: any;
}

export default function RHFSelect({
                                      name,
                                      label,
                                      children,
                                      ...other
                                  }: IProps & SelectProps) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        error={!!error}
                    >{label}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        {...field}
                        fullWidth
                        label={label}
                        error={!!error}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                        }}
                        value={field.value}
                        {...other}
                    >
                        {children}
                    </Select>
                    {!!error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}
