import { useEffect, useMemo } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
// config
import { NAVBAR } from '../../config';
// components
import Scrollbar from '../../components/Scrollbar';
import { NavSectionVertical } from '../../components/nav-section';
//
import { SidebarConfigType } from './NavConfig';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { setNavbarHovered, setNavbarPinned } from "../../store/stores/GeneralStore";
import cssStyles from "../../utils/cssStyles";
import Logo from "../../components/Logo";
import { useGetApiGardenGetAllQuery, useGetApiOrganizationGetMyQuery } from '../../services/klaraApi';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../../components/Iconify';
import { useTranslation } from "react-i18next";
import {Permissions} from "../../utils/permissions";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------


export default function NavbarVertical() {
    const theme = useTheme();

    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    const {
        isNavbarOpen, isNavbarPinned, isNavbarHovered
    } = useAppSelector(state => state.general);
    const dispatch = useDispatch();

    const { data: myGardens } = useGetApiGardenGetAllQuery();
    const { data: mySchools } = useGetApiOrganizationGetMyQuery();

    const { t } = useTranslation();

    const extendeedConfig: SidebarConfigType[] = useMemo(() => (
        [
            {
                subheader: '',
                items: [
                    { title: t('navigation.plants'), path: PATH_DASHBOARD.plants, icon: <Iconify icon={'ic:baseline-eco'} />, requireRole: "KlaraManager" },
                    { title: t('navigation.users'), path: PATH_DASHBOARD.users, icon: <Iconify icon={'ic:baseline-people'} />, requirePermission: Permissions.User.Get },
                    { title: t('navigation.schools'), path: PATH_DASHBOARD.schools, icon: <Iconify icon={'ic:baseline-school'} />, requirePermission: "KlaraManager" },
                ],
            },
            {
                subheader: t('navigation.mySchools'),
                items: mySchools?.map(school => ({
                    title: school.name,
                    path: PATH_DASHBOARD.schoolById(school.id),
                    icon: <Iconify icon={'ic:baseline-school'} />,
                })) ?? []
            },
            {
                subheader: t('navigation.myGardens'),
                items: [...(myGardens?.map(garden => ({
                    title: garden.name,
                    path: PATH_DASHBOARD.gardenById(garden.id),
                    icon: <Iconify icon={'ic:baseline-eco'} />,
                })) ?? []),
                {
                    title: t('navigation.createGarden'),
                    path: PATH_DASHBOARD.newGarden,
                    icon: <Iconify icon={'ic:baseline-add'} />,
                    requireRoles: "Teacher"
                }
                ]
            }
        ]
    ),
        [myGardens, t, mySchools]);


    useEffect(() => {
        if (isNavbarHovered) {
            dispatch(setNavbarHovered({ isNavbarHovered: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
                overflowX: 'hidden',
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    ...(!isNavbarOpen && { alignItems: 'center' }),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Logo />

                    {isDesktop && isNavbarOpen && (
                        <CollapseButton
                            onToggleCollapse={() => dispatch(setNavbarPinned({ isNavbarPinned: !isNavbarPinned }))}
                            collapseClick={!isNavbarPinned} />
                    )}
                </Stack>

                <NavbarAccount isCollapse={!isNavbarOpen} />
            </Stack>

            <NavSectionVertical navConfig={extendeedConfig} isCollapse={!isNavbarOpen}
                sx={{ bgcolor: theme.palette.background.default }} />

            <Box sx={{ flexGrow: 1 }} />

        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isNavbarOpen ? NAVBAR.DASHBOARD_WIDTH : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                },
                ...(isNavbarPinned && {
                    position: 'absolute',
                }),
            }}
        >
            {!isDesktop && (
                <Drawer
                    open={isNavbarOpen}
                    onClose={() => dispatch(setNavbarPinned({ isNavbarPinned: false }))}
                    PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={() => dispatch(setNavbarHovered({ isNavbarHovered: true }))}
                    onMouseLeave={() => dispatch(setNavbarHovered({ isNavbarHovered: false }))}
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'dashed',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                            ...(!isNavbarOpen && {
                                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                            }),
                            ...(isNavbarHovered && {
                                ...cssStyles(theme).bgBlur(),
                                boxShadow: (theme) => theme.customShadows.z24,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
