import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useGetApiOrganizationGetAllQuery } from "../../services/klaraApi";
import { Grid, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import Iconify from "../../components/Iconify";
import { GridSortItem } from "@mui/x-data-grid/models/gridSortModel";
import { enqueueSnackbar } from "notistack";
import Enumerable from "linq";
import { CreateSchoolDialog } from "./CreateSchoolDialog";
import { useTranslation } from 'react-i18next';

export const SchoolsTable = () => {
    const [editActiveId, setEditActiveId] = useState<string | undefined | null>(null);
    const [CreateSchoolDialogOpen, setCreateSchoolDialogOpen] = useState(false);


    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        {
            field: 'email',
            sort: 'asc' as GridSortDirection,
        },
    ]);
    const { t } = useTranslation();

    const { data, error } = useGetApiOrganizationGetAllQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const rows = useMemo(() => {
        return Enumerable.from(data ?? [])
            .where(x => x.name.toLowerCase().includes(search.toLowerCase()))
            .toArray();
    }, [data, search]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('schools.userFetchError'), { variant: "error" });
        }
    }, [error, t]);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: t('schools.table.name').toString(),
            width: 200,
        },
        {
            field: 'userContact.firstName',
            headerName: t('schools.table.schoolContact').toString(),
            width: 200,
            renderCell: (params) => `${params.row.userContact?.firstName ?? ""} ${params.row.userContact?.lastName ?? ""}`,
        },
        {
            field: 'klaraContact.firstName',
            headerName: t('schools.table.klaraContact').toString(),
            width: 200,
            renderCell: (params) => `${params.row.klaraContact?.firstName ?? ""} ${params.row.klaraContact?.lastName ?? ""}`,
        },
        {
            field: 'actions',
            renderHeader: () => (
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton onClick={() => setCreateSchoolDialogOpen(true)}>
                        <Iconify icon={"mdi:plus"} />
                    </IconButton>
                </Stack>
            ),
            renderCell: (params) => (
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton onClick={() => setEditActiveId(params.id as string)}>
                        <Iconify icon={"mdi:pencil"} />
                    </IconButton>
                </Stack>
            ),
            flex: 1,
            align: "right",
            headerAlign: "right",
            sortable: false,
        }
    ], [t]);

    return (
        <>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={t('schools.search')}
                            variant="outlined"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Iconify icon={"mdi:magnify"} />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ height: 520, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                    />
                </Box>
            </Stack>

            <CreateSchoolDialog
                open={CreateSchoolDialogOpen}
                onClose={() => setCreateSchoolDialogOpen(false)}
            />
        </>
    );
}
