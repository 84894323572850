import {useEffect} from "react";
import {PATH_DASHBOARD} from "../../routes/paths";
import checkPermission from "./checkPermission";

import {useGetApiRolesGetMyQuery} from "../../services/klaraApi";

type RequirePermissionProps = {
    permission?: string;
    redirect?: boolean;
    children: React.ReactNode;
};

export default function RequirePermission(props: RequirePermissionProps) {
    const {data} = useGetApiRolesGetMyQuery();

    if(!props.permission) return <>{props.children}</>;

    return checkPermission(props.permission, data ?? []) ? <>{props.children}</> : <></>;
}
