import {useState} from 'react';
// @mui
import {MenuItem, Stack} from '@mui/material';
// components
import Image from '../../components/Image';
import MenuPopover from '../../components/MenuPopover';
import {IconButtonAnimate} from '../../components/animate';
import Iconify from "../../components/Iconify";
import {useAppSelector} from "../../store";
import Enumerable from "linq";
import {useDispatch} from "react-redux";
import {setLanguage} from "../../store/stores/GeneralStore";
import {useTranslation} from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
    {
        label: 'English',
        value: 'en',
        icon: 'twemoji-flag-for-flag-united-kingdom'
    },
    {
        label: 'Hrvatski',
        value: 'hr',
        icon: 'twemoji-flag-for-flag-croatia'
    }
];


// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const [open, setOpen] = useState<HTMLElement | null>(null);

    const lang = useAppSelector(state => state.general.language);
    const dispatch = useDispatch();
    const { t, i18n,  } = useTranslation();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (target?: string) => {
        setOpen(null);
        dispatch(setLanguage({language: target ?? lang}));
        i18n.changeLanguage(target ?? lang);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {bgcolor: 'action.selected'}),
                }}
            >
                <Iconify icon={Enumerable.from(LANGS).firstOrDefault(x => x.value === lang)?.icon ?? LANGS[0].icon}/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={() => handleClose()}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
                }}
            >
                <Stack spacing={0.75}>
                    {LANGS.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === lang}
                            onClick={() => handleClose(option.value)}
                        >
                            <Iconify icon={option.icon} sx={{width: 24, height: 24, mr: 1}}/>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
