// @mui
import {styled} from '@mui/material/styles';
import {Box, Typography, Avatar} from '@mui/material';
import {useAppSelector} from "../../store";
import {Link} from "react-router-dom";
import {getAvatarUrl} from "../../utils/getAvatarUrl";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

type Props = {
    isCollapse: boolean | undefined;
};

export default function NavbarAccount({isCollapse}: Props) {
    const {userId, email} =  useAppSelector(state => state.user);

    return (
        <Link to="#" color="inherit">
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: 'transparent',
                    }),
                }}
            >
                <Avatar src={getAvatarUrl(userId)}/>

                <Box
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    }}
                >
                    <Typography variant="subtitle2" noWrap>
                        {email ? email.substring(0, email.indexOf('@')) : ""}
                    </Typography>
                </Box>
            </RootStyle>
        </Link>
    );
}
