import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {InferType} from "yup";
import FormProvider from "../../components/hook-form/FormProvider";
import RHFTextField from "../../components/hook-form/RHFTextField";
import {Grid, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {loginUser} from "../../store/stores/UserStore";
import {usePostApiAuthenticationLoginMutation} from "../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {sleep} from "../../utils/sleep";
import {useNavigate} from "react-router-dom";
import Image from "../../components/Image";

const loginSchema = yup
    .object()
    .shape({
        email: yup.string().email().required(),
        password: yup.string().required().min(8),
        rememberMe: yup.boolean().required(),
    })
    .required();

export type RegistrationData = InferType<typeof loginSchema>;

const defaultValues: RegistrationData = {
    email: "",
    password: "",
    rememberMe: false,
};

export const LoginForm = () => {
    const methods = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues,
    });

    const {handleSubmit} = methods;

    const dispatch = useDispatch();
    const [login, {
        data: loginData,
        error: loginError,
        isLoading: loginIsLoading,
    }] = usePostApiAuthenticationLoginMutation();

    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const {t} = useTranslation();
    

    const onSubmit = async (data: RegistrationData) => {
        try {
            setError(null);

            const res = await login({
                userLoginCommand: {
                    email: data.email,
                    password: data.password,
                    rememberMe: data.rememberMe,
                }
            }).unwrap();
            dispatch(loginUser({token: res.token, refreshToken: res.refreshToken ?? "", email: res.email, userId: res.id}));
            await sleep(100);
        } catch (e: any) {
            if (e.data?.detail) {
                setError(e.data?.detail);
                return;
            }

            if (e.message) {
                setError(e.message);
                return;
            }

            console.log(e);
            setError(t('common.error'));
        }
    };

    return (
            <Stack direction="column" spacing={10} sx={{m:5}}>
                <Image src={"/images/logo@3x.png"} />
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {t("auth.title")}
                </Typography>

                <Grid container spacing={2} my={2}>
                    <Grid item xs={12} md={12}>
                        <RHFTextField
                            name="email"
                            label={t("auth.emailPlaceholder")}
                            fullWidth
                            autoFocus />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RHFTextField
                            name="password"
                            label={t("auth.passwordPlaceholder")}
                            fullWidth
                            type="password" />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                sx={{ textTransform: "none", fontWeight: "bold" }}
                                loading={loginIsLoading}
                            >
                                {t('auth.submit')}
                            </LoadingButton>
                        </Stack>
                        {error && <Typography variant="body2" color="error">{error}</Typography>}
                    </Grid>
                </Grid>
            </FormProvider>
        </Stack>
    );
};
