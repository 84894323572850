import {Container, Divider} from "@mui/material";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {useTranslation} from "react-i18next";

export const Dashboard = () => {

    const {t} = useTranslation();

    return (
        <Page title={t("pages.dashboard")}>
            <Container maxWidth='xl'>
                <HeaderBreadcrumbs
                    heading={t("pages.dashboard")}
                    links={[
                        {name: t("pages.dashboard"), href: "/dashboard"},
                    ]}
                />

            </Container>
        </Page>
    )
}
