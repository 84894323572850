import React from 'react';
import './App.css';
import './services/i18n';
import {router} from "./routes/router";
import {SnackbarProvider} from 'notistack';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store, useAppSelector} from "./store";
import {MotionLazyContainer} from "./components/animate";
import {RouterProvider} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18n from "./services/i18n";
import CustomLocalizationProvider from './components/CustomLocalizationProvider';

function App() {

    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <I18nextProvider i18n={i18n} defaultNS={"common"}>
                        <MotionLazyContainer>
                            <CustomLocalizationProvider>
                                <SnackbarProvider maxSnack={3}>
                                    <RouterProvider router={router}/>
                                </SnackbarProvider>
                            </CustomLocalizationProvider>
                        </MotionLazyContainer>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
