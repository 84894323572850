import {HubSensorReadingDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment";

export const RenderHubSensorReadingLog: React.FC<HubSensorReadingDto> = ({timestamp, sensorType, value}) => {
    const {t} = useTranslation();

    let formattedValue = value.toFixed(1);

    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t(`events.messages.hubSensorReading`, {sensorType: t(`events.sensorTypes.${sensorType}`), value: formattedValue, unit: t(`events.units.${sensorType}`)})}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")}
                </Typography>
            </Stack>
        </Paper>
    );
};
