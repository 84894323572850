import {LightControlReadingDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment";

export const RenderLightControlReadingLog: React.FC<LightControlReadingDto> = ({timestamp, isLightOn}) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t("events.messages.lightAction", {action: isLightOn ? t("events.messages.lightActionOn") : t("events.messages.lightActionOff")})}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")}
                </Typography>
            </Stack>
        </Paper>
    );
};
