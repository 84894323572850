import { hrHR as hrHRCore } from '@mui/material/locale';
import { GridLocaleText } from '@mui/x-data-grid';
import { Localization, getGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';

const hrHRGrid: Partial<GridLocaleText> = {
  // Root
  noRowsLabel: 'Nema podataka',
  noResultsOverlayLabel: 'Pronađeno 0 zapisa',

  // Density selector toolbar button text
  toolbarDensity: 'Kompaktnost',
  toolbarDensityLabel: 'Kompaktnost',
  toolbarDensityCompact: 'Kompaktno',
  toolbarDensityStandard: 'Standardno',
  toolbarDensityComfortable: 'Komotno',

  // Columns selector toolbar button text
  toolbarColumns: 'Stupci',
  toolbarColumnsLabel: 'Odaberi stupce',

  // Filters toolbar button text
  toolbarFilters: 'Filteri',
  toolbarFiltersLabel: 'Prikaži filtere',
  toolbarFiltersTooltipHide: 'Sakrij filtere',
  toolbarFiltersTooltipShow: 'Prikaži filtere',
  toolbarFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtera aktivno` : `${count} filter aktivan`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Pretraga...',
  toolbarQuickFilterLabel: 'Pretraga',
  toolbarQuickFilterDeleteIconLabel: 'Očisti odabir',

  // Export selector toolbar button text
  toolbarExport: 'Izvezi podatke',
  toolbarExportLabel: 'Izvezi podatke',
  toolbarExportCSV: 'Izvezi u CSV formatu',
  toolbarExportPrint: 'Ispis',
  toolbarExportExcel: 'Preuzmi kao Excel datoteku',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Pronađi stupac',
  columnsPanelTextFieldPlaceholder: 'Naziv stupca',
  columnsPanelDragIconLabel: 'Promijeni redoslijed stupaca',
  columnsPanelShowAllButton: 'Prikaži sve',
  columnsPanelHideAllButton: 'Sakrij sve',

  // Filter panel text
  filterPanelAddFilter: 'Dodaj filter',
  filterPanelRemoveAll: 'Ukloni sve',
  filterPanelDeleteIconLabel: 'Izbriši',
  filterPanelLogicOperator: 'Logički operator',
  filterPanelOperator: 'Operator',
  filterPanelOperatorAnd: 'I',
  filterPanelOperatorOr: 'ILI',
  filterPanelColumns: 'Stupci',
  filterPanelInputLabel: 'Vrijednost',
  filterPanelInputPlaceholder: 'Vrijednost',

  // Filter operators text
  filterOperatorContains: 'sadrži',
  filterOperatorEquals: 'je jednak',
  filterOperatorStartsWith: 'počinje na',
  filterOperatorEndsWith: 'završava na',
  filterOperatorIs: 'je',
  filterOperatorNot: 'nije',
  filterOperatorAfter: 'je nakon',
  filterOperatorOnOrAfter: 'je na ili nakon',
  filterOperatorBefore: 'je prije',
  filterOperatorOnOrBefore: 'je na ili prije',
  filterOperatorIsEmpty: 'je prazan',
  filterOperatorIsNotEmpty: 'nije prazan',
  filterOperatorIsAnyOf: 'je jedan od',
  // 'filterOperator=': '=',
  // 'filterOperator!=': '!=',
  // 'filterOperator>': '>',
  // 'filterOperator>=': '>=',
  // 'filterOperator<': '<',
  // 'filterOperator<=': '<=',

  // Header filter operators text
  // headerFilterOperatorContains: 'Contains',
  // headerFilterOperatorEquals: 'Equals',
  // headerFilterOperatorStartsWith: 'Starts with',
  // headerFilterOperatorEndsWith: 'Ends with',
  // headerFilterOperatorIs: 'Is',
  // headerFilterOperatorNot: 'Is not',
  // headerFilterOperatorAfter: 'Is after',
  // headerFilterOperatorOnOrAfter: 'Is on or after',
  // headerFilterOperatorBefore: 'Is before',
  // headerFilterOperatorOnOrBefore: 'Is on or before',
  // headerFilterOperatorIsEmpty: 'Is empty',
  // headerFilterOperatorIsNotEmpty: 'Is not empty',
  // headerFilterOperatorIsAnyOf: 'Is any of',
  // 'headerFilterOperator=': 'Equals',
  // 'headerFilterOperator!=': 'Not equals',
  // 'headerFilterOperator>': 'Greater than',
  // 'headerFilterOperator>=': 'Greater than or equal to',
  // 'headerFilterOperator<': 'Less than',
  // 'headerFilterOperator<=': 'Less than or equal to',

  // Filter values text
  filterValueAny: 'ikoji',
  filterValueTrue: 'istinito',
  filterValueFalse: 'neistinito',

  // Column menu text
  columnMenuLabel: 'Izbornik',
  columnMenuShowColumns: 'Pokaži stupce',
  columnMenuManageColumns: 'Organiziraj stupce',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Sakrij',
  columnMenuUnsort: 'Makni poredak',
  columnMenuSortAsc: 'Poredaj uzlazno',
  columnMenuSortDesc: 'Poredaj silazno',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtera aktivno` : `${count} filter aktivan`,
  columnHeaderFiltersLabel: 'Prikaži filtre',
  columnHeaderSortIconLabel: 'Poredaj',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count > 1
      ? `${count.toLocaleString()} redaka odabrano`
      : `${count.toLocaleString()} redak odabran`,

  // Total row amount footer text
  footerTotalRows: 'Ukupno redaka:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Odabir',
  checkboxSelectionSelectAllRows: 'Odaberi sve retke',
  checkboxSelectionUnselectAllRows: 'Poništi odabir',
  checkboxSelectionSelectRow: 'Odaberi redak',
  checkboxSelectionUnselectRow: 'Poništi odabir',

  // Boolean cell text
  booleanCellTrueLabel: 'da',
  booleanCellFalseLabel: 'ne',

  // Actions cell more text
  actionsCellMore: 'više',

  // Column pinning text
  pinToLeft: 'Prikvači lijevo',
  pinToRight: 'Prikvači desno',
  unpin: 'Otkvači',

  // Tree Data
  treeDataGroupingHeaderName: 'Grupa',
  treeDataExpand: 'vidi sadržaj',
  treeDataCollapse: 'sakrij sadržaj',

  // Grouping columns
  groupingColumnHeaderName: 'Grupiraj',
  groupColumn: (name) => `Grupiraj prema ${name}`,
  unGroupColumn: (name) => `Prestani grupirati prema ${name}`,

  // Master/detail
  detailPanelToggle: 'Detalji',
  expandDetailPanel: 'Prikaži',
  collapseDetailPanel: 'Sakrij',

  // Row reordering text
  rowReorderingHeaderName: 'Promijeni poredak redaka',

  // Aggregation
  aggregationMenuItemHeader: 'Agregacija',
  aggregationFunctionLabelSum: 'suma',
  aggregationFunctionLabelAvg: 'avg',
  aggregationFunctionLabelMin: 'min',
  aggregationFunctionLabelMax: 'max',
  aggregationFunctionLabelSize: 'veličina',
};

export const hrHR: Localization = getGridLocalization(hrHRGrid, hrHRCore);