import {forwardRef} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import {Box, BoxProps} from '@mui/material';
import Image from "./Image";
import {NavLink} from "react-router-dom";
import {useAppSelector} from "../store";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
    fixedType?: '_inv' | '';
}

const Logo = forwardRef<any, Props>(({disabledLink = false, sx, ...rest}, ref) => {
    const theme = useTheme();
    const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    const {isNavbarOpen} = useAppSelector(state => state.general);

    const logo = (
        <Box ref={ref} sx={{width: isNavbarOpen ? 140 : 76, height: "100%", cursor: 'pointer', ...sx}}>
            <Image src={"/logo.svg"}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <NavLink to={"/"}>{logo}</NavLink>;
});

export default Logo;
