import {TankReadingDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment";

export const RenderTankReadingLog: React.FC<TankReadingDto> = ({timestamp, tankType, level}) => {
    const {t} = useTranslation();

    let tankTranslation= t(`events.tankTypes.${tankType}`);

    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t("events.messages.tankReading", {tankType: tankTranslation, unit: t(`events.units.fluidLevel`), value: level})}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")}
                </Typography>
            </Stack>
        </Paper>
    );
};
