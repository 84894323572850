import {useGetApiRolesGetMyQuery} from "../../services/klaraApi";
import checkIsAdmin from "./checkIsAdmin";
import {ReactNode} from "react";

type RequireSuperAdminProps = {
    children: ReactNode;
    requireAdmin?: boolean;
}

export default function RequireSuperAdmin(props: RequireSuperAdminProps) {
    const {data} = useGetApiRolesGetMyQuery();

    return props.requireAdmin ? checkIsAdmin(data ?? []) || props.requireAdmin ? <>{props.children}</> : <></> : <>{props.children}</>;
}
