import {ReactNode} from 'react';
// @mui
import {Box, Typography} from '@mui/material';
//
import Breadcrumbs, {Props as BreadcrumbsProps} from './Breadcrumbs';
import {NavLink} from "react-router-dom";

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
    action?: ReactNode;
    heading: string;
    moreLink?: string | string[];
}

export default function HeaderBreadcrumbs({
                                              links,
                                              action,
                                              heading,
                                              moreLink = '' || [],
                                              sx,
                                              ...other
                                          }: Props) {
    return (
        <Box sx={{mb: 5, ...sx}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>
                        {heading}
                    </Typography>
                    <Breadcrumbs links={links} {...other} />
                </Box>

                {action && <Box sx={{flexShrink: 0}}>{action}</Box>}
            </Box>

            <Box sx={{mt: 2}}>
                {typeof moreLink == "string" ? (
                    <NavLink to={moreLink} target="_blank">
                        <Typography variant="body2">
                            {moreLink}
                        </Typography>
                    </NavLink>
                ) : (
                    moreLink.map((href) => (
                        <NavLink
                            key={href}
                            to={href}
                            target="_blank"
                        >
                            <Typography variant="body2">
                                {href}
                            </Typography>
                        </NavLink>
                    ))
                )}
            </Box>
        </Box>
    );
}
