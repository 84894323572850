import Page from "../../components/Page";
import RequirePermission from "../../components/component-guard/RequirePermission";
import { Permissions } from "../../utils/permissions";
import { Container, IconButton, Stack, Typography } from "@mui/material";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Iconify from "../../components/Iconify";
import { useState } from "react";
import { PlantsTable } from "../../sections/plants/PlantsTable";
import { useTranslation } from "react-i18next";

export const Plants = () => {

    const [createPlantDialogOpen, setCreatePlantDialogOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Page title={t('plants.title')}>
            <RequirePermission permission={Permissions.Plant.Get} redirect>
                <Container maxWidth='xl'>
                    <Typography variant="h4" gutterBottom>
                        {t('plants.title')}
                    </Typography>

                    <PlantsTable />

                </Container>
            </RequirePermission>
        </Page>
    )
}