import {AnyUnifiedReading} from "../../../utils/unifiedReadings";
import {useTranslation} from "react-i18next";
import {RenderPotSensorReading} from "./RenderPotSensorReading";
import {
    RenderPumpControlReadingLog
} from "./RenderPumpControlReadingLog";
import {RenderPotAdditionalPotLog} from "./RenderPotAdditionalPotLog";
import {Paper, Stack, Typography} from "@mui/material";
import {RenderHubSensorReadingLog} from "./RenderHubSensorReadingLog";
import {RenderLightControlReadingLog} from "./RenderLightControlReadingLog";
import {RenderTankReadingLog} from "./RenderTankReadingLog";
import {RenderPotHarvestLog} from "./RenderPotHarvestLog";
import { RenderPumpControlRequestLog } from "./RenderPumpControlRequestLog";

export const RenderAnyUnifiedPotReading: React.FC<AnyUnifiedReading> = (props) => {
    const {t} = useTranslation();
let a = {...props.data, potIndex: 1};
    switch (props.logType) {
        case "TankReading":
            return <RenderTankReadingLog {...props.data} />;
        case "HubSensorReading":
            return <RenderHubSensorReadingLog {...props.data} />;
        case "LightControlReading":
            return <RenderLightControlReadingLog {...props.data } />;
        case "SensorReading":
            return <RenderPotSensorReading {...{...props.data, potIndex: props.potIndex}} />;
        case "PumpControlReading":
            return <RenderPumpControlReadingLog {...{...props.data, potIndex: props.potIndex}} />;
        case "PumpControlRequest":
                return <RenderPumpControlRequestLog  {...{...props.data, potIndex: props.potIndex}} />;
        case "AdditionalPotLogDto":
            return <RenderPotAdditionalPotLog  {...{...props.data, potIndex: props.potIndex}} />;
        case "PotHarvestLog":
            return <RenderPotHarvestLog  {...{...props.data, potIndex: props.potIndex}} />;
        default:
            return (
                <Paper elevation={1} sx={{m: 2, p: 2}}>
                    <Stack
                        spacing={2}
                        justifyContent={"space-between"}
                    >
                        <Typography variant="body1" textAlign={"left"}>
                            {t("events.eventTypes.unknownEvent")}
                        </Typography>
                    </Stack>
                </Paper>
            );
    }
};
