export const Permissions = {
    User: {
        Get: "user:get",
        Create: "user:create",
        Update: "user:update",
    },

    Role: {
        Get: "role:get",
        Create: "role:create",
        Update: "role:update",
    },

    AdditionalUser: {
        Get: "additional-user:get",
        Create: "additional-user:create",
        Update: "additional-user:update",
    },

    Organization: {
        Get: "organization:get",
        Create: "organization:create",
        Update: "organization:update",
    },

    Plant: {
        Get: "plant:get",
        Create: "plant:create",
        Update: "plant:update",
    },

    Garden: {
        Get: "garden:get",
        Create: "garden:create",
        Update: "garden:update",
        Water: "garden:water",
        Monitor: "garden:monitor",
        PlantHarvest: "garden:plant-harvest",
        EnterAdditionalLogData: "garden:enter-additional-log-data",

        GetAdditionalLogData: "garden:additional-log:get",
        CreateAdditionalLogData: "garden:additional-log:create",
        UpdateAdditionalLogData: "garden:additional-log:update",
    },
}