import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Stack
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useDeleteApiUserByUserIdContactAndContactIdMutation,
    useGetApiRolesGetAllQuery,
    useGetApiUserByUserIdQuery,
    usePostApiUserByUserIdContactMutation,
    usePostApiUserMutation,
    usePutApiUserByUserIdContactAndContactIdMutation,
    usePutApiUserByUserIdMutation, usePutApiUserStudentByUserIdMutation,
    UserAddContactCommand,
    UserCreateCommand, UserDto, UserUpdateStudentCommand
} from "../../services/klaraApi";
import { useFieldArray, useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { useCallback, useEffect } from "react";
import RHFTextField from "../../components/hook-form/RHFTextField";
import { useSnackbar } from "notistack";
import RHFSwitch from "../../components/hook-form/RHFSwitch";
import RHFSelect from "../../components/hook-form/RHFSelect";
import Iconify from "../../components/Iconify";
import { useTranslation } from "react-i18next";


type UserCreateEditDialogProps = {
    existingUser: UserDto | null;
    open: boolean;
    onClose: () => void;
}

type FormData = UserUpdateStudentCommand;

const schema: yup.ObjectSchema<FormData> = yup.object({
    username: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    expiresAt: yup.string().optional().nullable(),
    isEnabled: yup.boolean().required(),
}).required();

const defaultValues: FormData = {
    username: "",
    firstName: "",
    lastName: "",
    expiresAt: null,
    isEnabled: true,
}

export const StudentEditDialog = ({ open, onClose, existingUser }: UserCreateEditDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm<FormData>({ defaultValues, resolver: yupResolver(schema) });


    const [updateUser, updateUserResult] = usePutApiUserStudentByUserIdMutation();

    const { t } = useTranslation();

    const handleSave = useCallback(async (values: FormData) => {
        if (!existingUser) return;
        console.log(values);
        try {
            await updateUser({ userId: existingUser.id, userUpdateStudentCommand: values }).unwrap();
            enqueueSnackbar(t('users.createEdit.updateSuccess'), { variant: "success" });
            onClose();
        } catch (e) {
            enqueueSnackbar(t('users.createEdit.updateFail'), { variant: "error" });
        }
    }, [enqueueSnackbar, existingUser, onClose, t]);

    useEffect(() => {
        if (existingUser) {
            methods.reset({
                firstName: existingUser.firstName,
                lastName: existingUser.lastName,
                username: existingUser.username,
                isEnabled: existingUser.isEnabled,
                expiresAt: null
            });
        }
    }, [existingUser, methods.reset]);

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
            <Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth>
                <DialogTitle>
                    {t('users.createEdit.editTitle')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12} md={12}>
                            <RHFTextField name={"username"} label={t('users.createEdit.username')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"firstName"} label={t('users.createEdit.firstName')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"lastName"} label={t('users.createEdit.lastName')} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFSwitch name={"isEnabled"} label={t('users.createEdit.enabled')} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={methods.handleSubmit((data) => handleSave(data), (data) => console.log("invalid", data))}>Save</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
