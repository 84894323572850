// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";

// ----------------------------------------------------------------------

interface IProps {
    name: string;
}

export default function RHFTextField({
                                         name,
                                         ...other
                                     }: IProps & TextFieldProps) {
    const { control, handleSubmit } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(() => 0);
                        }
                    }}
                    {...other}
                />
            )}
        />
    );
}
