import {PotHarvestLogDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment";
import { PotReading } from "../../../utils/unifiedReadings";

export const RenderPotHarvestLog: React.FC<PotHarvestLogDto & PotReading> = ({timestamp, plantName, createdBy, potIndex}) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t(`events.messages.potHarvested`, {plantName: plantName, index: potIndex})}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")} - {createdBy?.firstName} {createdBy?.lastName}
                </Typography>
            </Stack>
        </Paper>
    );
};
