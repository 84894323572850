import Page from "../../components/Page";
import RequirePermission from "../../components/component-guard/RequirePermission";
import {Permissions} from "../../utils/permissions";
import {Box, Container, Grid, Tab} from "@mui/material";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {useGetApiGardenByGardenIdQuery} from "../../services/klaraApi";
import {useParams} from "react-router-dom";
import {Hub} from "../../sections/garden/Hub";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useState} from "react";
import RequireRole from "../../components/component-guard/RequireRole";
import {useRole} from "../../hooks/useRole";
import {useTranslation} from "react-i18next";
import {GardenUsers} from "../../sections/garden/GardenUsers";
import { PATH_DASHBOARD } from "../../routes/paths";

export const Garden = () => {
    const {id} = useParams<{ id: string }>();

    const {isTeacher} = useRole();

    const {data: gardenData} = useGetApiGardenByGardenIdQuery(
        {
            gardenId: id ?? "",
        },
        {
            skip: !id,
        }
    );

    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const {t} = useTranslation();

    return (
        <Page title={gardenData?.name ?? ""}>
            <RequirePermission permission={Permissions.Plant.Get} redirect>
                <Container maxWidth='xl'>
                    <HeaderBreadcrumbs
                        heading={gardenData?.name ?? ""}
                        links={[
                            { name: gardenData?.name ?? "",
                            href: PATH_DASHBOARD.gardenById(gardenData?.id ?? id ?? "") },
                        ]}
                    />
                    <RequireRole role="Teacher">
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider', my: 4, mx: 2}}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t("garden.hubs")} value="1" />
                                    {isTeacher && <Tab label={t("garden.users")} value="2" />}
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Grid container spacing={2}>
                                    {gardenData?.hubs?.map(hub => (
                                        <Hub key={hub.id} hub={hub} gardenId={id ?? ""}/>
                                    ))}
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <GardenUsers gardenId={id ?? ""} organizationId={gardenData?.organizationId ?? ""}/>
                            </TabPanel>
                        </TabContext>
                    </RequireRole>

                </Container>
            </RequirePermission>
        </Page>
    )
}
