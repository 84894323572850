import { Container, Typography } from "@mui/material";
import RequirePermission from "../../components/component-guard/RequirePermission";
import { Permissions } from "../../utils/permissions";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Page from "../../components/Page";
import { UserTable } from "../../sections/users/UserTable";
import { useTranslation } from "react-i18next";

export const Users = () => {
    const { t } = useTranslation();

    return (
        <Page title={"Users"}>
            <RequirePermission permission={Permissions.Plant.Get} redirect>
                <Container maxWidth='xl'>
                    <Typography variant="h4" gutterBottom>
                        {t('users.title')}
                    </Typography>

                    <UserTable />

                </Container>
            </RequirePermission>
        </Page>
    )
}
