import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {Config} from "../config/index";
import {AppState} from "../store";
import {getStoredState} from "redux-persist";
import {persistConfig} from "../store/persistConfig";
import nProgress from "nprogress";
import {klaraApi} from "./klaraApi";
import {loginUser, logoutUser} from "../store/stores/UserStore";
import {sleep} from "../utils/sleep";

const baseQueryWithInterceptor: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError
    > = async (args, api, extraOptions) => {
        let state = await getStoredState(persistConfig) as AppState;

        nProgress.start();

        try {

            if(state.user.token === undefined) {
                await sleep(1000);

                state = await getStoredState(persistConfig) as AppState;
            }

            const baseQuery = fetchBaseQuery(
                {
                    baseUrl: Config.API_URL,
                    headers: {
                        "Authorization": `Bearer ${state.user.token ?? ""}`,
                    },
                });

            const result = await baseQuery(args, api, extraOptions);

            if (result.error && result.error.status === 401) {
                try {
                    const data = await api.dispatch(klaraApi.endpoints.postApiAuthenticationRefreshToken.initiate({
                        refreshTokenCommand: {
                            refreshToken: state.user.refreshToken ?? "",
                        }
                    })).unwrap();

                    api.dispatch(loginUser({
                        token: data.token,
                        refreshToken: data.refreshToken ?? "",
                        userId: data.id,
                        email: data.email
                    }));
                } catch (e) {
                    api.dispatch(logoutUser());
                } finally {
                    nProgress.done();
                }
            }

            return result;
        } catch (e) {
            console.log("Error", e);
        } finally {
            nProgress.done();
        }

        return {
            data: undefined,
            error: undefined,
            meta: undefined,
        };
    }
;

export const api = createApi({
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
});

