import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetApiPlantGetAllQuery } from "../../services/klaraApi";
import Enumerable from "linq";
import { Grid, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import Iconify from "../../components/Iconify";
import { PlantCreateEditDialog } from "./PlantCreateEditDialog";
import { useTranslation } from 'react-i18next';
import RequirePermission from '../../components/component-guard/RequirePermission';
import { Permissions } from '../../utils/permissions';

export const PlantsTable = () => {
    const { data, refetch } = useGetApiPlantGetAllQuery(undefined, { refetchOnMountOrArgChange: true });

    const [search, setSearch] = useState('');
    const [rows, setRows] = useState(data ?? []);

    const [editActiveId, setEditActiveId] = useState<string | undefined | null>(null);
    const [isReadOnlyView, setIsReadOnlyView] = useState(false);

    useEffect(() => {
        const filteredData = Enumerable.from(data ?? [])
            .where(x =>
                x.name.toLowerCase().includes(search.toLowerCase()) ||
                (x.nameLatin ?? "").toLowerCase().includes(search.toLowerCase()) ||
                (x.nameEnglish ?? "").toLowerCase().includes(search.toLowerCase())
            ).toArray();

        setRows(filteredData);
    }, [search, data]);

    const { t } = useTranslation();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: t('plants.table.name').toString(),
            width: 150,
        },
        {
            field: 'nameLatin',
            headerName: t('plants.table.nameLatin').toString(),
            width: 150,
        },
        {
            field: 'nameEnglish',
            headerName: t('plants.table.nameEnglish').toString(),
            type: 'number',
            width: 150,
        },

        {
            field: 'actions',
            renderHeader: () => (
                <RequirePermission permission={Permissions.Plant.Create}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <IconButton onClick={() => setEditActiveId(undefined)}>
                            <Iconify icon={"mdi:plus"} />
                        </IconButton>
                    </Stack>
                </RequirePermission>
            ),
            renderCell: (params) => (
                <Stack direction="row">
                <RequirePermission permission={Permissions.Plant.Create}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <IconButton onClick={() => setEditActiveId(params.id as string)}>
                            <Iconify icon={"mdi:pencil"} />
                        </IconButton>
                    </Stack>
                </RequirePermission>

                <RequirePermission permission={Permissions.Plant.Get}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <IconButton onClick={() => 
                        {
                            setIsReadOnlyView(true);
                            setEditActiveId(params.id as string)
                        }}>
                            <Iconify icon={"mdi:eye"} />
                        </IconButton>
                    </Stack>
                </RequirePermission>
                </Stack>
            ),
            flex: 1,
            align: "right",
            headerAlign: "right",
            sortable: false,
        }
    ], [t]);


    return (
        <>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={t('plants.search')}
                            variant="outlined"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Iconify icon={"mdi:magnify"} />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ height: 520, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        hideFooterPagination
                    />
                </Box>
            </Stack>

            <PlantCreateEditDialog
                open={editActiveId !== null}
                isReadOnly = {isReadOnlyView}
                existingPlantId={editActiveId}
                onClose={() => {
                    setEditActiveId(null);
                    refetch();
                }}
            />
        </>
    );
}
