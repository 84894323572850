import Iconify from "../../components/Iconify";
import {
    HubDto,
    useGetApiDeviceByHubIdQuery,
    useGetApiReadingsGetLatestHubReadingByHubIdQuery
} from "../../services/klaraApi";
import {useTheme} from '@mui/material/styles';
import {Box, Grid, IconButton, Paper, Stack, Typography} from "@mui/material";
import {colors} from "../../theme/palette";
import Image from "../../components/Image";
import {useTranslation} from "react-i18next";
import {PATH_DASHBOARD} from "../../routes/paths";
import {NavLink} from "react-router-dom";
import moment from "moment";
import { FillTank } from "../../pages/dashboard/details/FillTank";

type HubProps = {
    hub: HubDto
    gardenId: string
};

export const Hub = ({hub, gardenId}: HubProps) => {
    const theme = useTheme();

    const {data} = useGetApiDeviceByHubIdQuery(
        {
            hubId: hub.id ?? "",
        },
        {
            skip: !hub.id,
        }
    );

    const { data: latestHubReadings } = useGetApiReadingsGetLatestHubReadingByHubIdQuery(
        { hubId: hub.id ?? "" },
        { refetchOnMountOrArgChange: true, pollingInterval: 10000, skip: !hub.id },
    );
    const lightLastTurnedOn = moment(latestHubReadings?.lightControlReadings.find((t) => t.isLightOn)?.timestamp);
    const lightTurnedOnHours = lightLastTurnedOn ? lightLastTurnedOn.diff(moment(), "hours") : 0;

    const temperature = latestHubReadings?.hubSensorReadings
        .find((t) => t.sensorType === "Temperature")
        ?.value.toPrecision(3);
    const humidity = latestHubReadings?.hubSensorReadings.find((t) => t.sensorType === "Humidity")?.value;

    const {t} = useTranslation();

    //<Link to={PATH_DASHBOARD.hubById(hub.id, gardenId)}>
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Paper elevation={3} sx={{padding: 2}}>
                <Stack spacing={2}>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Iconify id={hub.id}
                                     icon={'ic:round-square'}
                                     style={{color: theme.palette.primary.main, fontSize: '20px'}}/>
                            <Typography variant="h6">{hub.name}</Typography>
                        </Stack>

                    <NavLink to={PATH_DASHBOARD.hubLogsById(hub.id, gardenId)}>
                            <IconButton>
                                <Iconify icon={'ic:baseline-history'} style={{color: theme.palette.primary.main}}/>
                            </IconButton>
                        </NavLink>
                    </Stack>

                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center">
                        <Stack
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                backgroundColor: colors.lightBlue,
                                borderTopLeftRadius: 12,
                                borderBottomRightRadius: 12,
                                padding: 2,
                                width: 128,
                                height: 128
                            }}>

                            <Image src={"/images/humidity.png"} sx={{width: 32, height: 32}}/>
                            <Typography variant="body1"
                                        textAlign="center">{humidity}% {"\n"} {t('garden.hubDetails.humidity')}</Typography>
                        </Stack>
                        <Stack
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                backgroundColor: colors.lightLightOrange,
                                borderTopLeftRadius: 12,
                                borderBottomRightRadius: 12,
                                padding: 2,
                                width: 128,
                                height: 128
                            }}>
                            <Image src={"/images/temperature.png"} sx={{width: 32, height: 32}}/>
                            <Typography variant="body1"
                                        textAlign="center">{temperature}  °C{"\n"} {t('garden.hubDetails.temperature')}</Typography>
                        </Stack>
                    </Stack>

                    <Stack>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            px={2}
                        >
                            {data?.pots.map(p =>
                                <Grid item xs={4} alignItems="center" justifyContent="center" display="flex">
                                    {p.plantId == null ? <Box/> :
                                        <Image src={"/images/plantGood.png"} sx={{width: 72, height: 72}}/>}
                                </Grid>
                            )}
                        </Grid>

                        <Grid
                            container
                            alignItems="center"
                            sx={{
                                backgroundColor: colors.lightOrange,
                                borderTopLeftRadius: 12,
                                borderBottomRightRadius: 12,
                                borderColor: colors.orange,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                p: 2
                            }}>
                            {data?.pots.map(p =>
                                <Grid item xs={4} alignItems="center" justifyContent="center" display="flex">
                                    <NavLink to={PATH_DASHBOARD.potById(p.id, hub.id, gardenId)}>
                                        <Typography
                                            variant="caption">{p.plant == null ? t("garden.pot.empty") : p.plant.name}</Typography>
                                    </NavLink>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>

                    <Stack spacing={2} direction="row">
                       <FillTank hubId={hub.id}/>
                    </Stack>
                </Stack>
            </Paper>
        </Grid>
    )
}


const potHub = () => {

}
