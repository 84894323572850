/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import en from '../../public/locales/en/common.json';
import hr from '../../public/locales/hr/common.json';

// react-i18next versions higher than 11.11.0
declare module 'react-i18next' {
    // and extend them!
    interface CustomTypeOptions {
        // custom namespace type if you changed it
        defaultNS: 'common';
        // custom resources type
        resources: {
            en: typeof en;
            hr: typeof hr;
        };
    }
}

const i18next = i18n.createInstance();

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        initImmediate: false,
        debug: true,
        ns: ['common'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        }
    });


export default i18next;
