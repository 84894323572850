function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
    page404: '/404',
    home: '/',
};

export const PATH_DASHBOARD = {
    root: path(ROOTS_DASHBOARD, ''),

    users: path(ROOTS_DASHBOARD, '/users'),
    plants: path(ROOTS_DASHBOARD, '/plants'),

    schools: path(ROOTS_DASHBOARD, '/schools'),
    schoolById: (id: string) => path(ROOTS_DASHBOARD, `/school/${id}`),

    garden: path(ROOTS_DASHBOARD, '/garden'),
    gardenById: (id: string) => path(ROOTS_DASHBOARD, `/garden/${id}`),
    newGarden: path(ROOTS_DASHBOARD, '/garden/new'),
    hubLogsById: (id: string, gardenId: string) => path(ROOTS_DASHBOARD, `/garden/${gardenId}/hub/${id}`),
    potById: (id: string, hubId: string, gardenId: string) => path(ROOTS_DASHBOARD, `/garden/${gardenId}/hub/${hubId}/pot/${id}`),
    newPotLog: (id: string, hubId: string, gardenId: string) => path(ROOTS_DASHBOARD, `/garden/${gardenId}/hub/${hubId}/pot/${id}/log`),



    profile: path(ROOTS_DASHBOARD, '/profile'),
}
