import {AdditionalPotLogDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import Image from "../../../components/Image";
import moment from "moment/moment";

export const RenderPotAdditionalPotLog: React.FC<AdditionalPotLogDto & {potIndex: number}> = ({
                                                                             additionalPotLogImages,
                                                                             timestamp,
                                                                             ec,
                                                                             height,
                                                                             ph,
                                                                             numberOfFruits,
                                                                             numberOfLeaves,
                                                                             observation,
                                                                             createdBy,
                                                                             potIndex
                                                                         }) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t(`events.eventTypes.AdditionalPotLog`)} - {t("common.defaultPotName")} {potIndex}
                </Typography>
                <Stack spacing={2} direction="row">
                    {ph && (
                        <Typography variant="body1" textAlign={"left"}>
                            {t(`events.messages.ph`, {ph, unit: t(`events.units.ph`)})}
                        </Typography>
                    )}
                    {ec && (
                        <Typography variant="body1" textAlign={"left"}>
                            {t(`events.messages.ec`, {ec, unit: t(`events.units.ec`)})}
                        </Typography>
                    )}
                    {height && (
                        <Typography variant="body1" textAlign={"left"}>
                            {t(`events.messages.height`, {height, unit: t(`events.units.height`)})}
                        </Typography>
                    )}
                </Stack>
                <Stack spacing={2} direction="row">
                    {(numberOfFruits !==null && numberOfFruits !== undefined) && (
                        <Typography variant="body1" textAlign={"left"}>
                            {t(`events.messages.numberOfFruits`, {numberOfFruits})}
                        </Typography>
                    )}
                    {(numberOfLeaves !== null && numberOfLeaves !== undefined) && (
                        <Typography variant="body1" textAlign={"left"}>
                            {t(`events.messages.numberOfLeaves`, {numberOfLeaves})}
                        </Typography>
                    )}
                </Stack>

                <Typography variant="body1" textAlign={"left"}>
                    {t(`events.messages.observation`, {observation})}
                </Typography>

                {additionalPotLogImages.length > 0 && <>
                    <Typography variant="body1" textAlign={"left"}>
                        {t(`events.messages.observationImages`)}
                    </Typography>
                    <Stack spacing={2} direction="row">
                        {additionalPotLogImages.map((image) => (
                            <Image
                                key={image.blobUrl}
                                src={image.blobUrl}
                                sx={{
                                    width: 200,
                                    maxHeight: 400
                                }}
                                borderRadius={1}
                            />
                        ))}
                    </Stack>
                </>}

                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")} - {createdBy?.firstName} {createdBy?.lastName}
                </Typography>
            </Stack>
        </Paper>
    );
};
