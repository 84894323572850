import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import {
    PaginatedUserFieldsEnumOrderByRecord,
    usePostApiUserGetPaginatedMutation,
    UserDto
} from "../../services/klaraApi";
import { Grid, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import Iconify from "../../components/Iconify";
import { BulkCreateStudentsDialog } from "./BulkCreateStudentsDialog";
import { GridSortItem } from "@mui/x-data-grid/models/gridSortModel";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {StudentEditDialog} from "./StudentEditDialog";

type StudentsTableProps = {
    organizationId: string;
}

export const StudentsTable = ({ organizationId }: StudentsTableProps) => {
    const [editActiveUser, setEditActiveUser] = useState<UserDto | null>(null);
    const [isBulkCreateDialogOpen, setIsBulkCreateDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        {
            field: 'email',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const { t } = useTranslation();

    const [fetchPaginatedUsers, { data, isLoading, error }] = usePostApiUserGetPaginatedMutation();

    const updateData = () => {
        fetchPaginatedUsers({
            paginatedUserFieldsEnumPaginatedRequest: {
                search,
                page,
                pageSize,
                orderBy: sortModel as PaginatedUserFieldsEnumOrderByRecord[],
            }
        });
    }

    useEffect(() => {
        updateData();
    }, [fetchPaginatedUsers, search, page, pageSize, sortModel]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('schools.userFetchError'), { variant: "error" });
        }
    }, [error, t]);

    const rows = useMemo(() => {
        return data?.data ?? [];
    }, [data]);

    const columns: GridColDef<UserDto>[] = useMemo(() => [
        {
            field: 'username',
            headerName: t('schools.students.username').toString(),
            width: 150,
        },
        {
            field: 'firstName',
            headerName: t('schools.students.firstName').toString(),
            width: 150,
        },
        {
            field: 'lastName',
            headerName: t('schools.students.lastName').toString(),
            width: 150,
        },
        {
            field: 'joinedAt',
            headerName: t('schools.students.joined').toString(),
            type: 'number',
            width: 150,
            renderCell: (params) => moment.utc(params.value as number).local().format('LL'),
        },
        {
            field: 'actions',
            renderHeader: () => (
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton onClick={() => setIsBulkCreateDialogOpen(true)}>
                        <Iconify icon={"mdi:plus"} />
                    </IconButton>
                </Stack>
            ),
            renderCell: (params) => (
                <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton onClick={() => setEditActiveUser(params.row)}>
                        <Iconify icon={"mdi:pencil"} />
                    </IconButton>
                </Stack>
            ),
            flex: 1,
            align: "right",
            headerAlign: "right",
            sortable: false,
        }
    ], [t]);


    return (
        <>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={t('schools.students.search')}
                            variant="outlined"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Iconify icon={"mdi:magnify"} />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ height: 520, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        rowCount={data?.total ?? 0}
                        paginationMode="server"
                        pageSizeOptions={[10, 25, 50, 100]}
                        onPaginationModelChange={(params) => {
                            setPage(params.page);
                            setPageSize(params.pageSize);
                        }}
                        paginationModel={{
                            page,
                            pageSize,
                        }}
                        sortModel={sortModel}
                        onSortModelChange={(params) => {
                            setSortModel(params);
                        }}
                        loading={isLoading}
                        columns={columns}
                    />
                </Box>
            </Stack>

            <BulkCreateStudentsDialog
                open={isBulkCreateDialogOpen}
                organizationId={organizationId}
                onClose={() => {
                    setIsBulkCreateDialogOpen(false);
                    updateData();
                }}
            />
            <StudentEditDialog
                open={editActiveUser !== null}
                existingUser={editActiveUser}
                onClose={() => {
                    setEditActiveUser(null);
                    updateData();
                }}/>
        </>
    );
}
