import {PotSensorReadingDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment/moment";

export const RenderPotSensorReading: React.FC<PotSensorReadingDto & {potIndex: number }> = ({timestamp, sensorType, value, potIndex}) => {
    const {t} = useTranslation();
    let formattedValue = value.toFixed(1);
    
    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t(`events.messages.sensorReading`, {
                        sensorType: t(`events.sensorTypes.${sensorType}`),
                        index: potIndex,
                        value: formattedValue,
                        unit: t(`events.units.${sensorType}`)
                    })}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")}
                </Typography>
            </Stack>
        </Paper>
    );
};

