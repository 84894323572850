import {useEffect, useState} from 'react';
// @mui
import {styled} from '@mui/material/styles';
import useResponsive from '../hooks/useResponsive';
// config
import {HEADER, NAVBAR} from '../config';
//
import NavbarVertical from './navbar/NavbarVertical';
import {useAppSelector} from "../store";
import {useDispatch} from "react-redux";
import AuthGuard from "../guards/AuthGuard";
import {Outlet} from "react-router-dom";
import DashboardHeader from "./header";
import ProgressBar from "../components/ProgressBar";
import {useTranslation} from "react-i18next";
import moment from "moment";

// ----------------------------------------------------------------------

type MainStyleProps = {
    collapseClick: boolean;
};

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({collapseClick, theme}) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16 + (collapseClick ? NAVBAR.DASHBOARD_WIDTH : NAVBAR.DASHBOARD_COLLAPSE_WIDTH),
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        })
    },
}));

// ----------------------------------------------------------------------

export const DashboardLayout = () => {
    const {isNavbarOpen, isNavbarPinned, isNavbarHovered} = useAppSelector(state => state.general);
    const dispatch = useDispatch();

    const isDesktop = useResponsive('up', 'lg');

    const [open, setOpen] = useState(false);

    const {t, i18n} = useTranslation();

    const lang = useAppSelector(state => state.general.language);

    useEffect(() => {
        i18n.changeLanguage(lang);
        moment.locale(lang);
    }, [lang, i18n]);

    return (
        <>
            <ProgressBar/>
            <AuthGuard>
                <DashboardHeader verticalLayout={true}/>

                <NavbarVertical/>

                <MainStyle collapseClick={isNavbarPinned} sx={{px: 2, mx: 2}}>
                    <Outlet/>
                </MainStyle>
            </AuthGuard>
        </>
    );
}
