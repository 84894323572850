import {
    AdditionalPotLogDto,
    HubDto,
    HubReadingsDto,
    HubSensorReadingDto,
    LightControlReadingDto, PotHarvestLogDto, PotReadingsDto,
    PotSensorReadingDto,
    PumpControlReadingDto,
    PumpControlRequestDto,
    TankReadingDto
} from "../services/klaraApi";

export type UnifiedLogType = "TankReading" | "HubSensorReading" | "LightControlReading" | "PumpControlReading" | "PumpControlRequest" | "SensorReading" | "AdditionalPotLogDto" | "PotHarvestLog";

export type UnifiedReading<
    T extends UnifiedLogType,
    V extends TankReadingDto | HubSensorReadingDto | LightControlReadingDto | PumpControlReadingDto | PotSensorReadingDto | AdditionalPotLogDto | PotHarvestLogDto,
> = {
    id: string;

    timestamp: string;
    message?: string;

    logType: T;
    data: V;
};

export type PotReading = {
    potIndex: number;
};

export type UnifiedPotReading<
    T extends UnifiedLogType,
    V extends PumpControlReadingDto | PotSensorReadingDto | AdditionalPotLogDto | PotHarvestLogDto,
> = UnifiedReading<T,V> & PotReading;

export type AnyUnifiedReading =
    | UnifiedReading<"TankReading", TankReadingDto>
    | UnifiedReading<"HubSensorReading", HubSensorReadingDto>
    | UnifiedReading<"LightControlReading", LightControlReadingDto>
    | UnifiedPotReading<"PumpControlReading", PumpControlReadingDto>
    | UnifiedPotReading<"PumpControlRequest", PumpControlRequestDto>
    | UnifiedPotReading<"SensorReading", PotSensorReadingDto>
    | UnifiedPotReading<"AdditionalPotLogDto", AdditionalPotLogDto>
    | UnifiedPotReading<"PotHarvestLog", PotHarvestLogDto>;

export type UnifiedReadings = AnyUnifiedReading[];


export const mapReadings = (readings: Partial<HubReadingsDto & PotReadingsDto>, hub: HubDto | null = null): UnifiedReadings => {
    const unifiedHubReadings: UnifiedReadings = [];

    readings?.tankReadings?.forEach((tankReading: TankReadingDto) => {
        unifiedHubReadings.push({
            id: tankReading.id,

            timestamp: tankReading.timestamp,

            logType: "TankReading",
            data: tankReading,
        });
    });

    readings?.hubSensorReadings?.forEach((hubSensorReading: HubSensorReadingDto) => {
        unifiedHubReadings.push({
            id: hubSensorReading.id,

            timestamp: hubSensorReading.timestamp,

            logType: "HubSensorReading",
            data: hubSensorReading,
        });
    });

    readings?.lightControlReadings?.forEach((lightControlReading: LightControlReadingDto) => {
        unifiedHubReadings.push({
            id: lightControlReading.id,

            timestamp: lightControlReading.timestamp,

            logType: "LightControlReading",
            data: lightControlReading,
        });
    });

    readings?.additionalPotLogs?.forEach((additionalPotLog: AdditionalPotLogDto) => {
        unifiedHubReadings.push({
            id: additionalPotLog.id,
            potIndex: readings.index!,
            timestamp: additionalPotLog.timestamp,

            logType: "AdditionalPotLogDto",
            data: additionalPotLog,
        });
    });

    readings?.pumpControlReadings?.forEach((pumpControlReading: PumpControlReadingDto) => {
        unifiedHubReadings.push({
            id: pumpControlReading.id,
            potIndex: readings.index!,
            timestamp: pumpControlReading.timestamp,

            logType: "PumpControlReading",
            data: pumpControlReading,
        });
    });

    readings?.potSensorReadings?.forEach((potSensorReading: PotSensorReadingDto) => {
        unifiedHubReadings.push({
            id: potSensorReading.id,
            potIndex: readings.index!,
            timestamp: potSensorReading.timestamp,

            logType: "SensorReading",
            data: potSensorReading,
        });
    });

    readings?.harvestLogs?.forEach((potHarvestLog: PotHarvestLogDto) => {
        unifiedHubReadings.push({
            id: potHarvestLog.id,

            timestamp: potHarvestLog.timestamp,
            potIndex: readings.index!,

            logType: "PotHarvestLog",
            data: potHarvestLog,
        });
    });

    readings?.pumpControlRequests?.forEach((pumpControlRequest: PumpControlRequestDto) => {
        unifiedHubReadings.push({
            id: pumpControlRequest.id,
            potIndex: readings.index!,
            timestamp: pumpControlRequest.timestamp,

            logType: "PumpControlRequest",
            data: pumpControlRequest,
        });
    });


    return unifiedHubReadings;
}
