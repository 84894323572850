import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import RHFTextField from "../../components/hook-form/RHFTextField";
import Iconify from "../../components/Iconify";
import { useDropzone } from "react-dropzone";
import { readFile } from "../../utils/readFile";
import {
    BulkCreateStudentRecord,
    BulkCreateStudentsResponse,
    usePostApiOrganizationByOrganizationIdBulkCreateStudentsMutation
} from "../../services/klaraApi";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";


type UserCreateEditDialogProps = {
    organizationId: string;
    open: boolean;
    onClose: () => void;
}

type FormData = {
    students: Array<BulkCreateStudentRecord>
};

const schema: yup.ObjectSchema<FormData> = yup.object({
    students: yup.array().of(
        yup.object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
        })
    ).required(),
}).required();

const defaultValues: FormData = {
    students: [{
        firstName: "",
        lastName: "",
    }],
}


export const BulkCreateStudentsDialog = ({ open, onClose, organizationId }: UserCreateEditDialogProps) => {
    const methods = useForm<FormData>({ defaultValues, resolver: yupResolver(schema) });
    const { fields, append, remove } = useFieldArray({ control: methods.control, name: "students" });
    const [createdStudents, setCreatedStudents] = useState<BulkCreateStudentsResponse | undefined>();

    const [bulkCreate, { data: bulkCreateRes, reset, isLoading, }] = usePostApiOrganizationByOrganizationIdBulkCreateStudentsMutation();

    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: t('schools.createStudents.firstName').toString(),
            width: 150,
        },
        {
            field: 'lastName',
            headerName: t('schools.createStudents.lastName').toString(),
            width: 150,
        },
        {
            field: 'userName',
            headerName: t('schools.createStudents.username').toString(),
            width: 150,
        },
        {
            field: 'password',
            headerName: t('schools.createStudents.password').toString(),
            width: 150,
        }
    ];

    React.useEffect(() => {
        if(!isLoading) {
            setCreatedStudents(bulkCreateRes)
        }
    }, [bulkCreateRes, isLoading])

    const handleSave = useCallback(async (values: FormData) => {
        console.log(values);

        try {
            const res = await bulkCreate({
                organizationId,
                bulkCreateStudentsCommand: {
                    students: values.students,
                }
            }).unwrap();

            console.log(res);
        } catch (e) {
            console.log(e);
        }

    }, [onClose]);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        console.log(acceptedFiles);

        const csv = await readFile(acceptedFiles[0]);

        const names = csv.split(",");

        const newFields = names.map((name) => {
            const [firstName, lastName] = name.split(" ");
            return {
                firstName,
                lastName,
            }
        });

        console.log(names);
        console.log(newFields);

        methods.setValue("students", newFields);
    }, [])
    const { getRootProps, getInputProps, isDragActive, open: openFilePicker } = useDropzone({ onDrop, noClick: true })

    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(moment().add("months", 6))

    const handleDownload = useCallback(() => {
        const csvContent = "data:text/csv;charset=utf-8," + createdStudents?.createdUsers.map((user) => {
            return `${user.firstName},${user.lastName},${user.userName},${user.password}`
        }).join("\n");

        const encodedUri = encodeURI(csvContent);

        const a = document.createElement('a');
        a.setAttribute('href', encodedUri);
        a.setAttribute('download', 'students.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }, [createdStudents]);

    const handleClose = useCallback(() => {
        reset();
        methods.reset();
        setCreatedStudents(undefined);
        onClose();
    }, [onClose]);

    moment.locale('hr');

    return (
        <>
            <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
                <Dialog
                    onClose={onClose} maxWidth={"lg"} fullWidth
                    {...getRootProps()}
                    open={open}
                    sx={{
                        border: isDragActive ? "2px dashed #ccc" : "none",
                    }}
                >
                    <DialogTitle>
                        {t('schools.createStudents.title')}
                    </DialogTitle>
                    <DialogContent dividers>

                        {createdStudents ? (
                            <Box sx={{ height: 520, width: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row.email}
                                    rows={createdStudents.createdUsers}
                                    columns={columns}
                                    hideFooterPagination
                                    disableRowSelectionOnClick
                                    rowSelectionModel={[]}
                                />
                            </Box>
                        ) : (
                            <>
                                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                    <Typography variant={"body2"}>
                                        {t('schools.createStudents.description')}
                                    </Typography>
                                    <IconButton
                                        onClick={openFilePicker}
                                    >
                                        <Iconify icon={"mdi:file-upload"} />
                                    </IconButton>
                                </Stack>

                                <Stack direction="row" spacing={2}>
                                    <Typography variant={"body2"}>
                                        {t('schools.createStudents.expirationDateDescription')}
                                    </Typography>
                                    <DatePicker
                                        label={t('schools.createStudents.expirationDate')}
                                        value={selectedDate}
                                        onChange={(newValue) => setSelectedDate(newValue ?? moment())}
                                    />
                                </Stack>

                                <Stack spacing={2} mt={2}>
                                    {fields.map((field, index) => (
                                        <Stack spacing={2} direction={"row"} key={field.id} alignItems={"center"}>
                                            <Grid item md={5}>
                                                <RHFTextField name={`students.${index}.firstName`}
                                                    label={t('schools.createStudents.firstName')} />
                                            </Grid>
                                            <Grid item md={5}>
                                                <RHFTextField name={`students.${index}.lastName`}
                                                    label={t('schools.createStudents.lastName')} />
                                            </Grid>
                                            <Grid item md={2}>
                                                <IconButton onClick={() => {
                                                    remove(index);
                                                }}>
                                                    <Iconify icon={"mdi:close"} />
                                                </IconButton>
                                            </Grid>
                                        </Stack>
                                    ))}

                                </Stack>
                                <Button sx={{ m: 2 }} onClick={() => append({
                                    firstName: "",
                                    lastName: "",
                                })}>
                                    {t('schools.createStudents.add')}
                                </Button>
                            </>
                        )}
                        <input {...getInputProps()} style={{ display: "none" }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            {createdStudents ? t('common.close') : t('common.cancel')}
                        </Button>
                        {createdStudents ?
                            <Button onClick={handleDownload}>{t('schools.createStudents.download')}</Button> :
                            <Button
                                onClick={methods.handleSubmit(
                                    (data) => handleSave(data),
                                    (data) => console.log("invalid", data))}>{t('common.save')}</Button>}
                    </DialogActions>
                </Dialog>
            </FormProvider>
        </>
    );
}
