import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';
import { PATH_DASHBOARD } from "../../routes/paths";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/stores/UserStore";
import { getAvatarUrl } from "../../utils/getAvatarUrl";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { api } from '../../services/api';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Profile',
        linkTo: PATH_DASHBOARD.profile,
    },
    {
        label: 'Settings',
        linkTo: '/dashboard/management/settings',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const { email, userId } = useAppSelector(state => state.user);
    const dispatch = useDispatch();

    const [open, setOpen] = useState<HTMLElement | null>(null);
    const [openSettings, setOpenSettings] = useState(false);
    const handleOpenSettings = () => {
        setOpenSettings(true);
        handleClose();
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const logout = () => {
        dispatch(logoutUser());
        dispatch(api.util.resetApiState());
        handleClose();
    };

    const { t } = useTranslation();

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={getAvatarUrl(userId)} alt={email} />
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {t('accountPopover.loggedInAs')}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem sx={{ m: 1 }} onClick={logout}>{t('accountPopover.logout')}</MenuItem>
            </MenuPopover>
        </>
    );
}
