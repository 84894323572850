import { Container, Typography } from "@mui/material";
import RequirePermission from "../../components/component-guard/RequirePermission";
import { Permissions } from "../../utils/permissions";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Page from "../../components/Page";
import { useParams } from "react-router-dom";
import { useGetApiOrganizationByOrganizationIdQuery } from "../../services/klaraApi";
import { StudentsTable } from "../../sections/school/StudentsTable";

export const School = () => {
    const { id } = useParams<{ id: string }>();

    const { data: school } = useGetApiOrganizationByOrganizationIdQuery(
        {
            organizationId: id ?? "",
        },
        {
            skip: !id,
        }
    );

    return (
        <Page title={school?.name ?? ""}>
            <RequirePermission permission={Permissions.Plant.Get} redirect>
                <Container maxWidth='xl'>
                    <Typography variant="h4" gutterBottom>
                        {school?.name ?? ""}
                    </Typography>
                    {id && <StudentsTable organizationId={id} />}

                </Container>
            </RequirePermission>
        </Page>
    )
}
