// @mui
import {Box, ListItemText} from '@mui/material';
// type
import {NavItemProps} from '../type';
//
import Iconify from '../../Iconify';
import {ListItemStyle, ListItemTextStyle, ListItemIconStyle} from './style';
import {isExternalLink} from '../index';
import {Link} from "react-router-dom";

// ----------------------------------------------------------------------

export function NavItemRoot({item, isCollapse, open = false, active, onOpen}: NavItemProps) {
    const {title, path, icon, info, children} = item;

    const renderContent = (
        <>
            {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
            <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse}/>
            {!isCollapse && (
                <>
                    {info && info}
                    {children && <ArrowIcon open={open}/>}
                </>
            )}
        </>
    );

    if (children) {
        return (
            <ListItemStyle onClick={onOpen} activeRoot={active}>
                {renderContent}
            </ListItemStyle>
        );
    }

    return isExternalLink(path) ? (
        <ListItemStyle component={Link as any} href={path} target="_blank" rel="noopener">
            {renderContent}
        </ListItemStyle>
    ) : (
        <Link to={path}>
            <ListItemStyle activeRoot={active}>{renderContent}</ListItemStyle>
        </Link>
    );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({item, open = false, active = false, onOpen}: NavItemSubProps) {
    const {title, path, info, children} = item;

    const renderContent = (
        <>
            <DotIcon active={active}/>
            <ListItemText disableTypography primary={title}/>
            {info && info}
            {children && <ArrowIcon open={open}/>}
        </>
    );

    if (children) {
        return (
            <ListItemStyle onClick={onOpen} activeSub={active} subItem>
                {renderContent}
            </ListItemStyle>
        );
    }

    return isExternalLink(path) ? (
        <ListItemStyle component={Link as any} href={path} target="_blank" rel="noopener" subItem>
            {renderContent}
        </ListItemStyle>
    ) : (
        <Link to={path}>
            <ListItemStyle activeSub={active} subItem>
                {renderContent}
            </ListItemStyle>
        </Link>
    );
}

// ----------------------------------------------------------------------

type DotIconProps = {
    active: boolean;
};

export function DotIcon({active}: DotIconProps) {
    return (
        <ListItemIconStyle>
            <Box
                component="span"
                sx={{
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    bgcolor: 'text.disabled',
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shorter,
                        }),
                    ...(active && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main',
                    }),
                }}
            />
        </ListItemIconStyle>
    );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
    open: boolean;
};

export function ArrowIcon({open}: ArrowIconProps) {
    return (
        <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{width: 16, height: 16, ml: 1}}
        />
    );
}
