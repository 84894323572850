import {Container} from "@mui/material";
import {LoginForm} from "../../sections/auth/LoginForm";

export const LoginPage = () => {
    return (
        <Container maxWidth={"xl"}>
            <LoginForm/>
        </Container>
    );
};
