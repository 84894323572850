import {
    OrganizationCreateCommand, useGetApiOrganizationGetAllQuery,
    usePostApiOrganizationMutation,
    usePostApiUserGetPaginatedMutation
} from "../../services/klaraApi";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useCallback, useEffect } from "react";
import FormProvider from "../../components/hook-form/FormProvider";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import RHFTextField from "../../components/hook-form/RHFTextField";
import RHFSelect from "../../components/hook-form/RHFSelect";
import { useTranslation } from "react-i18next";


type CreateSchoolDialogProps = {
    open: boolean;
    onClose: () => void;
}

type FormData = OrganizationCreateCommand & {
    phone: string;
    email: string;
    address: string;
};

const schema: yup.ObjectSchema<FormData> = yup.object({
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().required().email(),
    address: yup.string().required(),

    userContactId: yup.string().required(),
    klaraContactId: yup.string().required(),
}).required();

const defaultValues: FormData = {
    name: "",
    phone: "",
    email: "",
    address: "",

    userContactId: "",
    klaraContactId: "",
}

export const CreateSchoolDialog = ({ open, onClose }: CreateSchoolDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm<FormData>({ defaultValues, resolver: yupResolver(schema) });

    const [createOrganization] = usePostApiOrganizationMutation();
    const { refetch } = useGetApiOrganizationGetAllQuery(undefined);

    const { t } = useTranslation();

    const handleSave = useCallback(async (values: FormData) => {
        console.log(values);

        try {
            await createOrganization({ organizationCreateCommand: values }).unwrap();
            enqueueSnackbar(t('schools.create.success'), { variant: "success" });
            refetch();
            onClose();
        } catch (e) {
            enqueueSnackbar(t('schools.create.fail'), { variant: "error" });
        }

    }, [onClose, t]);

    const [fetchPaginatedUsers, { data, }] = usePostApiUserGetPaginatedMutation();

    useEffect(() => {
        fetchPaginatedUsers({ paginatedUserFieldsEnumPaginatedRequest: { page: 0, pageSize: 100 } });
    }, [fetchPaginatedUsers]);

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={"lg"}
                fullWidth
            >
                <DialogTitle>
                    {t('schools.create.title')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"name"}
                                label={t('schools.create.name')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"address"}
                                label={t('schools.create.address')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"phone"}
                                label={t('schools.create.phone')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"email"}
                                label={t('schools.create.email')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect name={"userContactId"} label={t('schools.create.userContact')} fullWidth>
                                {data?.data?.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect name={"klaraContactId"} label={t('schools.create.klaraContact')} fullWidth>
                                {data?.data?.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        {t('common.close')}
                    </Button>
                    <Button
                        onClick={methods.handleSubmit(
                            (data) => handleSave(data),
                            (data) => console.log("invalid", data))}>{t('common.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
