import { LoadingButton } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TankType, usePostApiDeviceByHubIdTankFilledMutation } from "../../../services/klaraApi";

type FillTankProps = {hubId: string};

export const FillTank = ({hubId}:FillTankProps) => {
    const [selectedTankType, setSelectedTankType] = useState<TankType | null>();
    const {t} = useTranslation();

    const [fillTank, {isLoading }] = usePostApiDeviceByHubIdTankFilledMutation();


    const handleSubmit = useCallback(async () => {
        if(selectedTankType){
            try {
                await fillTank({ hubId, deviceTankFilledCommand: {tankType: selectedTankType} }).unwrap();
                enqueueSnackbar(t('tankFill.success'), { variant: "success" })
            } catch {
                enqueueSnackbar(t('tankFill.failure'), { variant: "error" });
            }
        }            
    }, [enqueueSnackbar, t, selectedTankType]);

    return  (
    <>
    <LoadingButton sx={{ width: 0.5 }}
        variant="contained"
        onClick={handleSubmit}
        loading={isLoading} disabled={!selectedTankType}>
        {t("tankFill.fillTank")}
    </LoadingButton>

    <FormControl variant="outlined" fullWidth>
        <InputLabel>{t('tankFill.tankType')}</InputLabel>
        <Select fullWidth name={"tankType"} value={selectedTankType} onChange={(e)=>setSelectedTankType(e.target.value as TankType | null) } label={t('tankFill.tankType')}>
            <MenuItem key={"Water"} value={"water"}>{t('events.tankTypes.Water')}</MenuItem>
            <MenuItem key={"NutrientsP15N30K15"} value={"NutrientsP15N30K15"}>{t('events.tankTypes.NutrientsP15N30K15')}</MenuItem>
            <MenuItem key={"NutrientsP16N8K23"} value={"NutrientsP16N8K23"}>{t('events.tankTypes.NutrientsP16N8K23')}</MenuItem>
            <MenuItem key={"NutrientsP20N20K20"} value={"NutrientsP20N20K20"}>{t('events.tankTypes.NutrientsP20N20K20')}</MenuItem>
        </Select>
    </FormControl>
    </>
    )
}