import Page from "../../components/Page";
import RequirePermission from "../../components/component-guard/RequirePermission";
import { Permissions } from "../../utils/permissions";
import { Button, Container, Grid, MenuItem, Typography } from "@mui/material";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {
    GardenCreateCommand,
    useGetApiGardenGetAllQuery,
    useGetApiOrganizationGetMyQuery,
    usePostApiGardenMutation
} from "../../services/klaraApi";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import RHFTextField from "../../components/hook-form/RHFTextField";
import RHFSelect from "../../components/hook-form/RHFSelect";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useTranslation } from "react-i18next";

type FormData = GardenCreateCommand;

const schema: yup.ObjectSchema<FormData> = yup.object({
    name: yup.string().required(),
    organizationId: yup.string().required()
}).required();

const defaultValues = {
    name: "",
    organizationId: ""
}

export const NewGarden = () => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm<FormData>({ defaultValues, resolver: yupResolver(schema) });
    const navigate = useNavigate();

    const { refetch } = useGetApiGardenGetAllQuery();
    const { data: mySchools } = useGetApiOrganizationGetMyQuery();
    const [createGarden] = usePostApiGardenMutation();
    const { t } = useTranslation();

    const handleSave = useCallback(async (values: FormData) => {
        try {
            const gardenId = await createGarden({ gardenCreateCommand: values }).unwrap();
            await refetch();
            navigate(PATH_DASHBOARD.gardenById(gardenId));
            enqueueSnackbar(t('newGarden.success'), { variant: "success" });
        } catch (e) {
            enqueueSnackbar(t('newGarden.failure'), { variant: "error" });
        }

    }, [enqueueSnackbar, t]);

    return (
        <Page title={t('newGarden.title')}>
            <RequirePermission permission={Permissions.Plant.Get} redirect>
                <Container maxWidth='xl'>
                    <Typography variant="h4" gutterBottom>
                        {t('newGarden.title')}
                    </Typography>

                    <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFTextField name={"name"} label={t('newGarden.namePlaceholder')} />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFSelect
                                    name={"organizationId"}
                                    label={t('newGarden.organizationPlaceholder')}
                                >
                                    {mySchools?.map(school => (
                                        <MenuItem key={school.id} value={school.id}>{school.name}</MenuItem>
                                    ))}
                                </RHFSelect>
                            </Grid>
                        </Grid>

                        <Button
                            sx={{ mt: 2 }}
                            type={"submit"}
                            variant={"contained"}
                        >
                            {t('newGarden.create')}
                        </Button>
                    </FormProvider>
                </Container>
            </RequirePermission>
        </Page>
    )
}
