import { Container, Typography } from "@mui/material";
import RequirePermission from "../../components/component-guard/RequirePermission";
import { Permissions } from "../../utils/permissions";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Page from "../../components/Page";
import { SchoolsTable } from "../../sections/school/SchoolsTable";
import { useTranslation } from "react-i18next";

export const Schools = () => {
    const { t } = useTranslation();

    return (
        <Page title={t('schools.title')}>
            <RequirePermission permission="klaraManager" redirect>
                <Container maxWidth='xl'>
                    <Typography variant="h4" gutterBottom>
                        {t('schools.title')}
                    </Typography>

                    <SchoolsTable />

                </Container>
            </RequirePermission>
        </Page>
    )
}
