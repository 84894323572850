import { Navigate, createBrowserRouter } from "react-router-dom";
import { LoginPage } from "../pages/auth/Login";
import { DashboardLayout } from "../layout/Dashboard";
import { Users } from "../pages/dashboard/Users";
import { Plants } from "../pages/dashboard/Plants";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Schools } from "../pages/dashboard/Schools";
import React from "react";
import { Garden } from "../pages/dashboard/Garden";
import { School } from "../pages/dashboard/School";
import { HubDetails } from "../pages/dashboard/details/HubDetails";
import { PotDetails } from "../pages/dashboard/details/PotDetails";
import { NewGarden } from "../pages/dashboard/NewGarden";
import { NewPotLog } from "../pages/dashboard/NewPotLog";
import { DataPrivacy } from "../pages/data-privacy/DataPrivacy";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/dashboard/plants" replace />,
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [
            {
                path: '',
                element: <Dashboard />,
            },
            {
                path: 'users',
                element: <Users />,
            },
            {
                path: 'schools',
                element: <Schools />,
            },
            {
                path: 'plants',
                element: <Plants />,
            },
            {
                path: 'garden/:id',
                element: <Garden />,
            },
            {
                path: 'garden/new',
                element: <NewGarden />,
            },
            {
                path: 'garden/:id/hub/:hubId',
                element: <HubDetails />,
            },
            {
                path: 'garden/:id/hub/:hubId/pot/:potId',
                element: <PotDetails />,
            },
            {
                path: 'garden/:id/hub/:hubId/pot/:potId/log',
                element: <NewPotLog />,
            },
            {
                path: 'school/:id',
                element: <School />,
            }
        ],
    },
    {
        path: '/data-erasure',
        element: <DataPrivacy />,
    },
]);
