import { PickersLocaleText } from '@mui/x-date-pickers/locales/utils/pickersLocaleTextApi';
import { getPickersLocalization } from '@mui/x-date-pickers/locales/utils/getPickersLocalization'
import { TimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';

const views: Record<TimeViewWithMeridiem, string> = {
  hours: 'sati',
  minutes: 'minute',
  seconds: 'sekunde',
  meridiem: 'podne',
};

const hrHRPickers: Partial<PickersLocaleText<any>> = {
  // Calendar navigation
  previousMonth: 'Prošli mjesec',
  nextMonth: 'Idući mjesec',

  // View navigation
  openPreviousView: "otvori prošli pregled",
  openNextView: 'otvori idući pregled',
  calendarViewSwitchingButtonAriaLabel: (view) =>
    view === 'year'
      ? "godišnji pregled je aktivan, prebaci na kalendarski pregled"
      : "kalendarski pregled je aktivan, prebaci na godišnji pregled",

  // DateRange placeholders
  start: 'Početak',
  end: 'Kraj',

  // Action bar
  cancelButtonLabel: 'Poništi',
  clearButtonLabel: 'Očisti odabir',
  okButtonLabel: 'Prihvati',
  todayButtonLabel: 'Danas',

  // Toolbar titles
  datePickerToolbarTitle: 'Odaberi datum',
  dateTimePickerToolbarTitle: 'Odaberi datum i vrijeme',
  timePickerToolbarTitle: 'Odaberi vrijeme',
  dateRangePickerToolbarTitle: 'Odaberi raspon datuma',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Odaberi ${views[view]}. ${
      time === null
        ? 'Vrijeme nije odabrano'
        : `Odabrano vrijeme je ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} sati`,
  minutesClockNumberText: (minutes) => `${minutes} minuta`,
  secondsClockNumberText: (seconds) => `${seconds} sekundi`,

  // Digital clock labels
  selectViewText: (view) => `Odaberi ${views[view]}`,

  // Calendar labels
  calendarWeekNumberHeaderLabel: 'Broj tjedna',
  calendarWeekNumberHeaderText: '#',
  calendarWeekNumberAriaLabelText: (weekNumber) => `Tjedan ${weekNumber}`,
  calendarWeekNumberText: (weekNumber) => `${weekNumber}`,

  // Open picker labels
  openDatePickerDialogue: (value, utils) =>
    value !== null && utils.isValid(value)
      ? `Odaberi datum, trenutno odabrani datum je ${utils.format(value, 'fullDate')}`
      : 'ODaberi datum',
  openTimePickerDialogue: (value, utils) =>
    value !== null && utils.isValid(value)
      ? `Odaberi vrijeme, trenutno odabrano vrijeme je ${utils.format(value, 'fullTime')}`
      : "Odaberi vrijeme",

  // Table labels
  timeTableLabel: 'odaberi datum',
  dateTableLabel: "odaberi vrijeme",

  // Field section placeholders
  fieldYearPlaceholder: (params) => 'Y'.repeat(params.digitAmount),
  fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
  fieldDayPlaceholder: () => 'DD',
  fieldWeekDayPlaceholder: (params) => (params.contentType === 'letter' ? 'EEEE' : 'EE'),
  fieldHoursPlaceholder: () => 'hh',
  fieldMinutesPlaceholder: () => 'mm',
  fieldSecondsPlaceholder: () => 'ss',
  fieldMeridiemPlaceholder: () => 'aa',
};

export const hrHR = getPickersLocalization(hrHRPickers);