import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    AdditionalPotLogCreateCommand,
    useGetApiDeviceByHubIdQuery,
    useGetApiGardenByGardenIdQuery, useGetApiPlantByPlantIdQuery,
    useGetApiPotByPotIdQuery,
    usePostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadMutation,
    usePostApiPotByPotIdAdditionalPotLogMutation
} from "../../services/klaraApi";
import React, {useCallback, useState} from "react";
import {PATH_DASHBOARD} from "../../routes/paths";
import {Permissions} from "../../utils/permissions";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {Button, Container, Link, Stack, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import Page from "../../components/Page";
import RequirePermission from "../../components/component-guard/RequirePermission";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormProvider from "../../components/hook-form/FormProvider";
import RHFTextField from "../../components/hook-form/RHFTextField";
import {useDropzone} from "react-dropzone";
import {readFile} from "../../utils/readFile";
import Image from "../../components/Image";

type FormValues = AdditionalPotLogCreateCommand;

const defaultValues: FormValues = {
    ph: null,
    ec: null,
    height: null,
    numberOfLeaves: null,
    numberOfFruits: null,
    observation: null,
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    ph: yup.number().nullable(),
    ec: yup.number().nullable(),
    height: yup.number().nullable(),
    numberOfLeaves: yup.number().nullable(),
    numberOfFruits: yup.number().nullable(),
    observation: yup.string().nullable(),
}).required();

export const NewPotLog = () => {
    const {t} = useTranslation();
    const {id, hubId, potId} = useParams<{ id: string, hubId: string, potId: string }>();
    const navigate = useNavigate();

    const {data: myGarden} = useGetApiGardenByGardenIdQuery({gardenId: id ?? ""}, {skip: !id});
    const {data: hubData} = useGetApiDeviceByHubIdQuery({hubId: hubId ?? ""}, {skip: !hubId});
    const {data: potData} = useGetApiPotByPotIdQuery({potId: potId ?? ""}, {skip: !potId});
    const {data: plantData} = useGetApiPlantByPlantIdQuery({plantId: potData?.plantId ?? ""}, {skip: !potData?.plantId});

    const [createAdditionalPotLog] = usePostApiPotByPotIdAdditionalPotLogMutation();
    const methods = useForm<FormValues>({ defaultValues, resolver: yupResolver(schema) });
    const [pickedPhotos, setPickedPhotos] = useState<File[]>([]);
    const onDrop = (files: File[]) => {
        setPickedPhotos((prevState) => [...prevState, ...files]);
    }
    const {getRootProps, getInputProps, isDragActive, open: openFilePicker} = useDropzone({onDrop, noClick: true})
    const [uploadPhoto] = usePostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadMutation();

    const onSave = async (values: FormValues) => {
        if (!potId || !hubId || !id) {
            return;
        }
        try {
            const additionalLogId = await createAdditionalPotLog({
                potId,
                additionalPotLogCreateCommand: values,
            }).unwrap();

            await Promise.all(
                pickedPhotos.map((photo) => {
                    const formData = new FormData();
                    formData.append("File", photo);
                    return uploadPhoto({
                        potId,
                        additionalLogId,
                        body: formData as any,
                    }).unwrap();
                }),
            );

            navigate(PATH_DASHBOARD.potById(potId, hubId, id));
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Page title={`Pot ${potData?.index}`}>
            <RequirePermission permission={Permissions.Garden.Get} redirect>
                <Container maxWidth='xl'
                    {...getRootProps()}
                    sx={{
                        border: isDragActive ? "2px dashed #ccc" : "none",
                        pb: 16,
                    }}>
                    <HeaderBreadcrumbs
                        heading={`${t('common.defaultPotName')} ${potData?.index ?? ""}`}
                        links={[
                            { 
                                name: myGarden?.name ?? "",
                                href: PATH_DASHBOARD.gardenById(myGarden?.id ?? id ?? "") 
                            },
                            {
                                name: hubData?.name ?? t('common.defaultHubName'),
                                href: PATH_DASHBOARD.hubLogsById(hubId ?? "", id ?? "")
                            },
                            {name: `${t('common.defaultPotName')} ${potData?.index ?? ""}:` + " " + plantData?.name ?? ""}
                        ]}
                        action={
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    href={id && hubId && potId && PATH_DASHBOARD.potById(id, hubId, potId)}
                                >
                                    {t('common.cancel')}
                                </Button>
                            </Stack>
                        }
                    />
                    <input {...getInputProps()} style={{display: "none"}} />
                    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSave)}>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <RHFTextField name={"ph"} label={t('events.newPotLog.ph')} />
                                <RHFTextField name={"ec"} label={t('events.newPotLog.ec')} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <RHFTextField name={"height"} label={t('events.newPotLog.height')} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <RHFTextField name={"numberOfLeaves"} label={t('events.newPotLog.numberOfLeaves')} />
                                <RHFTextField name={"numberOfFruits"} label={t('events.newPotLog.numberOfFruits')} />
                            </Stack>
                            <RHFTextField name={"observation"} label={t('events.newPotLog.observation')} fullWidth />
                            <Link onClick={openFilePicker} href="#">
                                {t('events.newPotLog.clickOrDragPhotos')}
                            </Link>
                            <Stack direction="row" spacing={2}>
                                {pickedPhotos.map((photo) => (
                                    <Image
                                        key={photo.name}
                                        src={URL.createObjectURL(photo)}
                                        alt={photo.name}
                                        sx={{width: 200, height: 200}}
                                        onClick={() => {
                                            setPickedPhotos((prevState) => prevState.filter((p) => p !== photo));
                                        }}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                        <Button
                            sx={{ mt: 2 }}
                            type={"submit"}
                            variant={"contained"}
                        >
                            {t('common.save')}
                        </Button>
                    </FormProvider>
                </Container>
            </RequirePermission>
        </Page>
    )
};
