export const unitMap: any = {
    'growthTempMin': 'Temperature',
    'growthTempMax': 'Temperature',
    "waterCapacityMin": 'SoilMoisture',
    'waterCapacityMax': 'SoilMoisture',
    'photoPeriodMin': 'Hour',
    'photoPeriodMax': 'Hour',
    'plantDensityMin': 'm2',
    'plantDensityMax': 'm2',
    'timeToHarvestMin': 'Day',
    'timeToHarvestMax': 'Day',
    'nutrientRatioN': 'Ratio',
    'nutrientRatioP205': 'Ratio',
    'nutrientRatioK20': "Ratio",
    "nutrientNeedP205": "MassRatio",
    'nutrientNeedK20': 'MassRatio',
    'nutrientNeedN': 'MassRatio'
}