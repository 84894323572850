import { useEffect, useState } from 'react';
// @mui
import {
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography,
} from '@mui/material';
// utils
// _mock_
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';
import moment from "moment";
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    const [notifications, setNotifications] = useState<Array<Notification> | null>(null);
    const { enqueueSnackbar } = useSnackbar();


    const [open, setOpen] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMarkAllAsRead = async () => {

    };

    const handleNotificationClick = async (notification: Notification) => {

    }

    const handleRefresh = async (showNProgress = false) => {

    }

    useEffect(() => {
        if (!open) return;

        handleRefresh();
    }, [open]);

    useEffect(() => {
        let interval = setInterval(handleRefresh, 1000 * 60);

        window.addEventListener("blur", () => {
            handleClose();

            clearInterval(interval);

            interval = setInterval(handleRefresh, 5000 * 60);
        });

        window.addEventListener("focus", () => {
            clearInterval(interval);

            interval = setInterval(handleRefresh, 1000 * 60);
        });

        handleRefresh();

    }, []);

    const { t } = useTranslation();

    const totalUnRead = 0;

    return (
        <>
            <IconButtonAnimate
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" width={20} height={20} />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">{t('notificationsPopover.title')}</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {t('notificationsPopover.unreadMessages', { count: totalUnRead })}
                        </Typography>
                    </Box>

                    <Button
                        size="small"
                        variant="text"
                        onClick={() => handleRefresh(true)}
                    >
                        <Iconify icon={'eva:refresh-outline'} width={20} height={20} />
                    </Button>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20} />
                            </IconButtonAnimate>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                {t('notificationsPopover.unread')}
                            </ListSubheader>
                        }
                    >

                    </List>

                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                {t('notificationsPopover.read')}
                            </ListSubheader>
                        }
                    >

                    </List>
                </Scrollbar>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ p: 1 }}>
                    <Button fullWidth disableRipple>
                        View All
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

function NotificationItem({
    notification,
    onNotificationClick
}: { notification: Notification, onNotificationClick: (notification: Notification) => void }) {
    const { enqueueSnackbar } = useSnackbar()

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
            }}
            onClick={() => onNotificationClick(notification)}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }}>{""}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={"title"}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled',
                        }}>
                        <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                        time
                    </Typography>
                }
            />
        </ListItemButton>
    );
}