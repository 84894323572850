import { useEffect } from 'react';
import NProgress from 'nprogress';
// @mui
import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import {useNavigation} from "react-router-dom";

// ----------------------------------------------------------------------

export default function ProgressBar() {
    const theme = useTheme();
    const {state} = useNavigation();

    NProgress.configure({ showSpinner: false });

    useEffect(() => {
        if (state == "loading") {
            NProgress.start();
        }
        if (state == "idle") {
            NProgress.done();
        }
    }, [state]);

    return (
        <GlobalStyles
            styles={{
                '#nprogress': {
                    pointerEvents: 'none',
                    '& .bar': {
                        top: 0,
                        left: 0,
                        height: 2,
                        width: '100%',
                        position: 'fixed',
                        zIndex: theme.zIndex.snackbar,
                        backgroundColor: theme.palette.primary.main,
                        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
                    },
                    '& .peg': {
                        right: 0,
                        opacity: 1,
                        width: 100,
                        height: '100%',
                        display: 'block',
                        position: 'absolute',
                        transform: 'rotate(3deg) translate(0px, -4px)',
                        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
                    },
                },
            }}
        />
    );
}
