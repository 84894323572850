import {ReactNode, useMemo} from 'react';
// @mui
import {CssBaseline} from '@mui/material';
import {createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';
// hooks
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';
import { hrHR as pickerHR } from './componentTranslations/datepicker/hrHR';
import { hrHR as datagridHR } from './componentTranslations/datagrid/hrHR';
import { hrHR as hrHRCore } from '@mui/material/locale';

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
    lang: string | undefined;
};

export default function ThemeProvider({children, lang}: Props) {
    const themeMode = 'light';
    const themeDirection = 'ltr';
    const isLight = themeMode === 'light';

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: isLight ? palette.light : palette.dark,
            typography,
            breakpoints,
            shape: {borderRadius: 8},
            direction: themeDirection,
            shadows: isLight ? shadows.light : shadows.dark,
            customShadows: isLight ? customShadows.light : customShadows.dark,
        }),
        [isLight, themeDirection]
    );

    let theme = createTheme(themeOptions);
    componentsOverride(theme);
    if(lang ==='hr'){
        theme = createTheme({components: theme.components}, themeOptions, hrHRCore, pickerHR, datagridHR);
    }

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </MUIThemeProvider>
    );
}
