import { useEffect, useState } from "react";
import { useAppSelector } from "../store";
import i18n from "../services/i18n";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from '@mui/x-date-pickers';
import ThemeProvider from "../theme";
import * as React from "react";
import 'moment/locale/hr';

type CustomLocalizationProviderProps = {
    children: React.ReactNode;
};

export default function CustomLocalizationProvider ({children }:CustomLocalizationProviderProps) {
    const lang = useAppSelector(state => state.general.language);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={lang}
        dateFormats={{
            normalDate: 'DD/MM/YYYY',
            keyboardDate: 'DD/MM/YYYY',
        }}>
             <ThemeProvider lang={lang}>
                {children}
             </ThemeProvider>
        </LocalizationProvider>
    );
  }
  