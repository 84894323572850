import { api } from "./api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiAuthenticationRegister: build.mutation<
      PostApiAuthenticationRegisterApiResponse,
      PostApiAuthenticationRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/Register`,
        method: "POST",
        body: queryArg.userRegistrationCommand,
      }),
    }),
    postApiAuthenticationLogin: build.mutation<
      PostApiAuthenticationLoginApiResponse,
      PostApiAuthenticationLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/Login`,
        method: "POST",
        body: queryArg.userLoginCommand,
      }),
    }),
    postApiAuthenticationRefreshToken: build.mutation<
      PostApiAuthenticationRefreshTokenApiResponse,
      PostApiAuthenticationRefreshTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/RefreshToken`,
        method: "POST",
        body: queryArg.refreshTokenCommand,
      }),
    }),
    postApiAuthenticationForgotPassword: build.mutation<
      PostApiAuthenticationForgotPasswordApiResponse,
      PostApiAuthenticationForgotPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/ForgotPassword`,
        method: "POST",
        body: queryArg.userForgotPasswordCommand,
      }),
    }),
    postApiAuthenticationResetPassword: build.mutation<
      PostApiAuthenticationResetPasswordApiResponse,
      PostApiAuthenticationResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/ResetPassword`,
        method: "POST",
        body: queryArg.userResetPasswordCommand,
      }),
    }),
    postApiAuthenticationLogout: build.mutation<
      PostApiAuthenticationLogoutApiResponse,
      PostApiAuthenticationLogoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Authentication/Logout`,
        method: "POST",
        body: queryArg.userLogoutCommand,
      }),
    }),
    postApiDeviceRegister: build.mutation<
      PostApiDeviceRegisterApiResponse,
      PostApiDeviceRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/Register`,
        method: "POST",
        body: queryArg.deviceCreateCommand,
      }),
    }),
    getApiDeviceByHubId: build.query<
      GetApiDeviceByHubIdApiResponse,
      GetApiDeviceByHubIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Device/${queryArg.hubId}` }),
    }),
    deleteApiDeviceByHubId: build.mutation<
      DeleteApiDeviceByHubIdApiResponse,
      DeleteApiDeviceByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}`,
        method: "DELETE",
      }),
    }),
    putApiDeviceByHubId: build.mutation<
      PutApiDeviceByHubIdApiResponse,
      PutApiDeviceByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}`,
        method: "PUT",
      }),
    }),
    getApiDeviceByHubIdDeviceStatus: build.query<
      GetApiDeviceByHubIdDeviceStatusApiResponse,
      GetApiDeviceByHubIdDeviceStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/deviceStatus`,
      }),
    }),
    postApiDeviceByHubIdManualPumpAction: build.mutation<
      PostApiDeviceByHubIdManualPumpActionApiResponse,
      PostApiDeviceByHubIdManualPumpActionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/manualPumpAction`,
        method: "POST",
        body: queryArg.manualPumpActionCommand,
      }),
    }),
    postApiDeviceByHubIdManualLightAction: build.mutation<
      PostApiDeviceByHubIdManualLightActionApiResponse,
      PostApiDeviceByHubIdManualLightActionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/manualLightAction`,
        method: "POST",
        body: queryArg.manualLightActionCommand,
      }),
    }),
    postApiDeviceByHubIdForceNewSensorReadings: build.mutation<
      PostApiDeviceByHubIdForceNewSensorReadingsApiResponse,
      PostApiDeviceByHubIdForceNewSensorReadingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/forceNewSensorReadings`,
        method: "POST",
      }),
    }),
    postApiDeviceByHubIdTankFilled: build.mutation<
      PostApiDeviceByHubIdTankFilledApiResponse,
      PostApiDeviceByHubIdTankFilledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/tankFilled`,
        method: "POST",
        body: queryArg.deviceTankFilledCommand,
      }),
    }),
    postApiDeviceByHubIdSetLightSchedule: build.mutation<
      PostApiDeviceByHubIdSetLightScheduleApiResponse,
      PostApiDeviceByHubIdSetLightScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/setLightSchedule`,
        method: "POST",
        body: queryArg.deviceSetLightScheduleCommand,
      }),
    }),
    postApiDeviceByHubIdClearDeviceSettings: build.mutation<
      PostApiDeviceByHubIdClearDeviceSettingsApiResponse,
      PostApiDeviceByHubIdClearDeviceSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Device/${queryArg.hubId}/clearDeviceSettings`,
        method: "POST",
        body: queryArg.clearDeviceSettingsCommand,
      }),
    }),
    getApiGardenGetAll: build.query<
      GetApiGardenGetAllApiResponse,
      GetApiGardenGetAllApiArg
    >({
      query: () => ({ url: `/api/Garden/getAll` }),
    }),
    getApiGardenByGardenId: build.query<
      GetApiGardenByGardenIdApiResponse,
      GetApiGardenByGardenIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Garden/${queryArg.gardenId}` }),
    }),
    putApiGardenByGardenId: build.mutation<
      PutApiGardenByGardenIdApiResponse,
      PutApiGardenByGardenIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Garden/${queryArg.gardenId}`,
        method: "PUT",
        body: queryArg.gardenUpdateCommand,
      }),
    }),
    postApiGarden: build.mutation<
      PostApiGardenApiResponse,
      PostApiGardenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Garden`,
        method: "POST",
        body: queryArg.gardenCreateCommand,
      }),
    }),
    getApiGardenByGardenIdUsers: build.query<
      GetApiGardenByGardenIdUsersApiResponse,
      GetApiGardenByGardenIdUsersApiArg
    >({
      query: (queryArg) => ({ url: `/api/Garden/${queryArg.gardenId}/users` }),
    }),
    postApiGardenByGardenIdUsers: build.mutation<
      PostApiGardenByGardenIdUsersApiResponse,
      PostApiGardenByGardenIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Garden/${queryArg.gardenId}/users`,
        method: "POST",
        body: queryArg.addUsersAccessCommand,
      }),
    }),
    deleteApiGardenByGardenIdUsers: build.mutation<
      DeleteApiGardenByGardenIdUsersApiResponse,
      DeleteApiGardenByGardenIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Garden/${queryArg.gardenId}/users`,
        method: "DELETE",
        body: queryArg.removeUsersAccessCommand,
      }),
    }),
    getApiOrganizationByOrganizationId: build.query<
      GetApiOrganizationByOrganizationIdApiResponse,
      GetApiOrganizationByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization/${queryArg.organizationId}`,
      }),
    }),
    putApiOrganizationByOrganizationId: build.mutation<
      PutApiOrganizationByOrganizationIdApiResponse,
      PutApiOrganizationByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization/${queryArg.organizationId}`,
        method: "PUT",
        body: queryArg.organizationUpdateCommand,
      }),
    }),
    getApiOrganizationGetAll: build.query<
      GetApiOrganizationGetAllApiResponse,
      GetApiOrganizationGetAllApiArg
    >({
      query: () => ({ url: `/api/Organization/GetAll` }),
    }),
    getApiOrganizationGetMy: build.query<
      GetApiOrganizationGetMyApiResponse,
      GetApiOrganizationGetMyApiArg
    >({
      query: () => ({ url: `/api/Organization/GetMy` }),
    }),
    postApiOrganization: build.mutation<
      PostApiOrganizationApiResponse,
      PostApiOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization`,
        method: "POST",
        body: queryArg.organizationCreateCommand,
      }),
    }),
    postApiOrganizationByOrganizationIdCreateUser: build.mutation<
      PostApiOrganizationByOrganizationIdCreateUserApiResponse,
      PostApiOrganizationByOrganizationIdCreateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization/${queryArg.organizationId}/createUser`,
        method: "POST",
        body: queryArg.userCreateCommand,
      }),
    }),
    postApiOrganizationByOrganizationIdBulkCreateStudents: build.mutation<
      PostApiOrganizationByOrganizationIdBulkCreateStudentsApiResponse,
      PostApiOrganizationByOrganizationIdBulkCreateStudentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization/${queryArg.organizationId}/bulkCreateStudents`,
        method: "POST",
        body: queryArg.bulkCreateStudentsCommand,
      }),
    }),
    getApiOrganizationByOrganizationIdUsers: build.query<
      GetApiOrganizationByOrganizationIdUsersApiResponse,
      GetApiOrganizationByOrganizationIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Organization/${queryArg.organizationId}/users`,
      }),
    }),
    getApiPlantByPlantId: build.query<
      GetApiPlantByPlantIdApiResponse,
      GetApiPlantByPlantIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Plant/${queryArg.plantId}` }),
    }),
    putApiPlantByPlantId: build.mutation<
      PutApiPlantByPlantIdApiResponse,
      PutApiPlantByPlantIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Plant/${queryArg.plantId}`,
        method: "PUT",
        body: queryArg.plantUpdateCommand,
      }),
    }),
    deleteApiPlantByPlantId: build.mutation<
      DeleteApiPlantByPlantIdApiResponse,
      DeleteApiPlantByPlantIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Plant/${queryArg.plantId}`,
        method: "DELETE",
      }),
    }),
    getApiPlantGetAll: build.query<
      GetApiPlantGetAllApiResponse,
      GetApiPlantGetAllApiArg
    >({
      query: () => ({ url: `/api/Plant/GetAll` }),
    }),
    postApiPlant: build.mutation<PostApiPlantApiResponse, PostApiPlantApiArg>({
      query: (queryArg) => ({
        url: `/api/Plant`,
        method: "POST",
        body: queryArg.plantCreateCommand,
      }),
    }),
    getApiPotByPotId: build.query<
      GetApiPotByPotIdApiResponse,
      GetApiPotByPotIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Pot/${queryArg.potId}` }),
    }),
    postApiPotByPotIdAdditionalPotLog: build.mutation<
      PostApiPotByPotIdAdditionalPotLogApiResponse,
      PostApiPotByPotIdAdditionalPotLogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Pot/${queryArg.potId}/AdditionalPotLog`,
        method: "POST",
        body: queryArg.additionalPotLogCreateCommand,
      }),
    }),
    putApiPotByPotIdAdditionalPotLogAndAdditionalLogId: build.mutation<
      PutApiPotByPotIdAdditionalPotLogAndAdditionalLogIdApiResponse,
      PutApiPotByPotIdAdditionalPotLogAndAdditionalLogIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Pot/${queryArg.potId}/AdditionalPotLog/${queryArg.additionalLogId}`,
        method: "PUT",
        body: queryArg.additionalPotLogUpdateCommand,
      }),
    }),
    postApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUpload: build.mutation<
      PostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadApiResponse,
      PostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Pot/${queryArg.potId}/AdditionalPotLog/${queryArg.additionalLogId}/Upload`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUpload:
      build.mutation<
        PostApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUploadApiResponse,
        PostApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Pot/${queryArg.potId}/AdditionalPotLogMobileUpload/${queryArg.additionalLogId}/Upload`,
          method: "POST",
          body: queryArg.additionalPotLogFileUploadMobileRequest,
        }),
      }),
    putApiPotByPotIdPotPlantedPlant: build.mutation<
      PutApiPotByPotIdPotPlantedPlantApiResponse,
      PutApiPotByPotIdPotPlantedPlantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Pot/${queryArg.potId}/PotPlantedPlant`,
        method: "PUT",
        body: queryArg.potPlantedPlantCommand,
      }),
    }),
    putApiPotByPotIdPotHarvestPlant: build.mutation<
      PutApiPotByPotIdPotHarvestPlantApiResponse,
      PutApiPotByPotIdPotHarvestPlantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Pot/${queryArg.potId}/PotHarvestPlant`,
        method: "PUT",
      }),
    }),
    getApiReadingsGetLatestPotReadingByPotId: build.query<
      GetApiReadingsGetLatestPotReadingByPotIdApiResponse,
      GetApiReadingsGetLatestPotReadingByPotIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetLatestPotReading/${queryArg.potId}`,
      }),
    }),
    getApiReadingsGetPotReadingsByPotId: build.query<
      GetApiReadingsGetPotReadingsByPotIdApiResponse,
      GetApiReadingsGetPotReadingsByPotIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetPotReadings/${queryArg.potId}`,
      }),
    }),
    getApiReadingsGetPotReadingsByDateByPotId: build.query<
      GetApiReadingsGetPotReadingsByDateByPotIdApiResponse,
      GetApiReadingsGetPotReadingsByDateByPotIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetPotReadingsByDate/${queryArg.potId}`,
        params: { date: queryArg.date },
      }),
    }),
    getApiReadingsGetLatestHubReadingByHubId: build.query<
      GetApiReadingsGetLatestHubReadingByHubIdApiResponse,
      GetApiReadingsGetLatestHubReadingByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetLatestHubReading/${queryArg.hubId}`,
      }),
    }),
    getApiReadingsGetHubReadingByHubId: build.query<
      GetApiReadingsGetHubReadingByHubIdApiResponse,
      GetApiReadingsGetHubReadingByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetHubReading/${queryArg.hubId}`,
      }),
    }),
    getApiReadingsGenerateHubReadingsZipByHubId: build.query<
      GetApiReadingsGenerateHubReadingsZipByHubIdApiResponse,
      GetApiReadingsGenerateHubReadingsZipByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GenerateHubReadingsZip/${queryArg.hubId}`,
        params: { timeZone: queryArg.timeZone },
      }),
    }),
    getApiReadingsGetHubReadingByDateByHubId: build.query<
      GetApiReadingsGetHubReadingByDateByHubIdApiResponse,
      GetApiReadingsGetHubReadingByDateByHubIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Readings/GetHubReadingByDate/${queryArg.hubId}`,
        params: { date: queryArg.date },
      }),
    }),
    getApiRolesGetAll: build.query<
      GetApiRolesGetAllApiResponse,
      GetApiRolesGetAllApiArg
    >({
      query: () => ({ url: `/api/Roles/getAll` }),
    }),
    getApiRolesGetMy: build.query<
      GetApiRolesGetMyApiResponse,
      GetApiRolesGetMyApiArg
    >({
      query: () => ({ url: `/api/Roles/getMy` }),
    }),
    getHealth: build.query<GetHealthApiResponse, GetHealthApiArg>({
      query: () => ({ url: `/health` }),
    }),
    getAuthenticated: build.query<
      GetAuthenticatedApiResponse,
      GetAuthenticatedApiArg
    >({
      query: () => ({ url: `/authenticated` }),
    }),
    getPermissions: build.query<
      GetPermissionsApiResponse,
      GetPermissionsApiArg
    >({
      query: () => ({ url: `/permissions` }),
    }),
    postTestPushNotification: build.mutation<
      PostTestPushNotificationApiResponse,
      PostTestPushNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/testPushNotification`,
        method: "POST",
        body: queryArg.firebaseNotificationRequest,
      }),
    }),
    getApiUserByUserId: build.query<
      GetApiUserByUserIdApiResponse,
      GetApiUserByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/User/${queryArg.userId}` }),
    }),
    putApiUserByUserId: build.mutation<
      PutApiUserByUserIdApiResponse,
      PutApiUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.userUpdateCommand,
      }),
    }),
    postApiUserGetPaginated: build.mutation<
      PostApiUserGetPaginatedApiResponse,
      PostApiUserGetPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/getPaginated`,
        method: "POST",
        body: queryArg.paginatedUserFieldsEnumPaginatedRequest,
      }),
    }),
    postApiUser: build.mutation<PostApiUserApiResponse, PostApiUserApiArg>({
      query: (queryArg) => ({
        url: `/api/User`,
        method: "POST",
        body: queryArg.userCreateCommand,
      }),
    }),
    putApiUserStudentByUserId: build.mutation<
      PutApiUserStudentByUserIdApiResponse,
      PutApiUserStudentByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/student/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.userUpdateStudentCommand,
      }),
    }),
    putApiUserByUserIdResetPassword: build.mutation<
      PutApiUserByUserIdResetPasswordApiResponse,
      PutApiUserByUserIdResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.userId}/resetPassword`,
        method: "PUT",
        body: queryArg.userResetStudentPasswordCommand,
      }),
    }),
    postApiUserByUserIdContact: build.mutation<
      PostApiUserByUserIdContactApiResponse,
      PostApiUserByUserIdContactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.userId}/contact`,
        method: "POST",
        body: queryArg.userAddContactCommand,
      }),
    }),
    putApiUserByUserIdContactAndContactId: build.mutation<
      PutApiUserByUserIdContactAndContactIdApiResponse,
      PutApiUserByUserIdContactAndContactIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.userId}/contact/${queryArg.contactId}`,
        method: "PUT",
        body: queryArg.userUpdateContactCommand,
      }),
    }),
    deleteApiUserByUserIdContactAndContactId: build.mutation<
      DeleteApiUserByUserIdContactAndContactIdApiResponse,
      DeleteApiUserByUserIdContactAndContactIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.userId}/contact/${queryArg.contactId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as klaraApi };
export type PostApiAuthenticationRegisterApiResponse =
  /** status 201 Created - logged in user. */ LoggedInUserDto;
export type PostApiAuthenticationRegisterApiArg = {
  /** User registration data. */
  userRegistrationCommand: UserRegistrationCommand;
};
export type PostApiAuthenticationLoginApiResponse =
  /** status 200 Ok - logged in user. */ LoggedInUserDto;
export type PostApiAuthenticationLoginApiArg = {
  /** Users login details. */
  userLoginCommand: UserLoginCommand;
};
export type PostApiAuthenticationRefreshTokenApiResponse =
  /** status 200 Ok - logged in user. */ LoggedInUserDto;
export type PostApiAuthenticationRefreshTokenApiArg = {
  /** User's refreshToken */
  refreshTokenCommand: RefreshTokenCommand;
};
export type PostApiAuthenticationForgotPasswordApiResponse =
  /** status 200 Ok - email has been sent. */ LoggedInUserDto;
export type PostApiAuthenticationForgotPasswordApiArg = {
  /** User's email details. */
  userForgotPasswordCommand: UserForgotPasswordCommand;
};
export type PostApiAuthenticationResetPasswordApiResponse =
  /** status 200 Success */ LoggedInUserDto;
export type PostApiAuthenticationResetPasswordApiArg = {
  userResetPasswordCommand: UserResetPasswordCommand;
};
export type PostApiAuthenticationLogoutApiResponse =
  /** status 200 Success */ undefined;
export type PostApiAuthenticationLogoutApiArg = {
  userLogoutCommand: UserLogoutCommand;
};
export type PostApiDeviceRegisterApiResponse =
  /** status 201 Device created. */ DeviceRegistrationDto;
export type PostApiDeviceRegisterApiArg = {
  /** Device and garden data. */
  deviceCreateCommand: DeviceCreateCommand;
};
export type GetApiDeviceByHubIdApiResponse =
  /** status 200 Device found. */ HubDto;
export type GetApiDeviceByHubIdApiArg = {
  /** Device id. */
  hubId: string;
};
export type DeleteApiDeviceByHubIdApiResponse =
  /** status 200 Success */ undefined;
export type DeleteApiDeviceByHubIdApiArg = {
  hubId: string;
};
export type PutApiDeviceByHubIdApiResponse =
  /** status 200 Success */ undefined;
export type PutApiDeviceByHubIdApiArg = {
  hubId: string;
};
export type GetApiDeviceByHubIdDeviceStatusApiResponse =
  /** status 200 Success */ boolean;
export type GetApiDeviceByHubIdDeviceStatusApiArg = {
  hubId: string;
};
export type PostApiDeviceByHubIdManualPumpActionApiResponse =
  /** status 200 Success */ undefined;
export type PostApiDeviceByHubIdManualPumpActionApiArg = {
  hubId: string;
  manualPumpActionCommand: ManualPumpActionCommand;
};
export type PostApiDeviceByHubIdManualLightActionApiResponse =
  /** status 200 Success */ undefined;
export type PostApiDeviceByHubIdManualLightActionApiArg = {
  hubId: string;
  manualLightActionCommand: ManualLightActionCommand;
};
export type PostApiDeviceByHubIdForceNewSensorReadingsApiResponse =
  /** status 200 Success */ boolean;
export type PostApiDeviceByHubIdForceNewSensorReadingsApiArg = {
  hubId: string;
};
export type PostApiDeviceByHubIdTankFilledApiResponse =
  /** status 200 Success */ undefined;
export type PostApiDeviceByHubIdTankFilledApiArg = {
  hubId: string;
  deviceTankFilledCommand: DeviceTankFilledCommand;
};
export type PostApiDeviceByHubIdSetLightScheduleApiResponse =
  /** status 200 Success */ undefined;
export type PostApiDeviceByHubIdSetLightScheduleApiArg = {
  hubId: string;
  deviceSetLightScheduleCommand: DeviceSetLightScheduleCommand;
};
export type PostApiDeviceByHubIdClearDeviceSettingsApiResponse =
  /** status 200 Success */ undefined;
export type PostApiDeviceByHubIdClearDeviceSettingsApiArg = {
  hubId: string;
  clearDeviceSettingsCommand: ClearDeviceSettingsCommand;
};
export type GetApiGardenGetAllApiResponse =
  /** status 200 Success */ GardenDto[];
export type GetApiGardenGetAllApiArg = void;
export type GetApiGardenByGardenIdApiResponse =
  /** status 200 Success */ GardenDto;
export type GetApiGardenByGardenIdApiArg = {
  gardenId: string;
};
export type PutApiGardenByGardenIdApiResponse =
  /** status 204 No Content */ undefined;
export type PutApiGardenByGardenIdApiArg = {
  gardenId: string;
  gardenUpdateCommand: GardenUpdateCommand;
};
export type PostApiGardenApiResponse = /** status 201 Created */ string;
export type PostApiGardenApiArg = {
  gardenCreateCommand: GardenCreateCommand;
};
export type GetApiGardenByGardenIdUsersApiResponse =
  /** status 200 Success */ UserGardenAccessDto[];
export type GetApiGardenByGardenIdUsersApiArg = {
  gardenId: string;
};
export type PostApiGardenByGardenIdUsersApiResponse = unknown;
export type PostApiGardenByGardenIdUsersApiArg = {
  gardenId: string;
  addUsersAccessCommand: AddUsersAccessCommand;
};
export type DeleteApiGardenByGardenIdUsersApiResponse = unknown;
export type DeleteApiGardenByGardenIdUsersApiArg = {
  gardenId: string;
  removeUsersAccessCommand: RemoveUsersAccessCommand;
};
export type GetApiOrganizationByOrganizationIdApiResponse =
  /** status 200 Success */ OrganizationDto;
export type GetApiOrganizationByOrganizationIdApiArg = {
  organizationId: string;
};
export type PutApiOrganizationByOrganizationIdApiResponse =
  /** status 200 Success */ OrganizationDto;
export type PutApiOrganizationByOrganizationIdApiArg = {
  organizationId: string;
  organizationUpdateCommand: OrganizationUpdateCommand;
};
export type GetApiOrganizationGetAllApiResponse =
  /** status 200 Success */ OrganizationDto[];
export type GetApiOrganizationGetAllApiArg = void;
export type GetApiOrganizationGetMyApiResponse =
  /** status 200 Success */ OrganizationDto[];
export type GetApiOrganizationGetMyApiArg = void;
export type PostApiOrganizationApiResponse =
  /** status 201 Created */ OrganizationDto;
export type PostApiOrganizationApiArg = {
  organizationCreateCommand: OrganizationCreateCommand;
};
export type PostApiOrganizationByOrganizationIdCreateUserApiResponse =
  /** status 201 Created */ UserCreateCommandResponse;
export type PostApiOrganizationByOrganizationIdCreateUserApiArg = {
  organizationId: string;
  userCreateCommand: UserCreateCommand;
};
export type PostApiOrganizationByOrganizationIdBulkCreateStudentsApiResponse =
  /** status 201 Created */ BulkCreateStudentsResponse;
export type PostApiOrganizationByOrganizationIdBulkCreateStudentsApiArg = {
  organizationId: string;
  bulkCreateStudentsCommand: BulkCreateStudentsCommand;
};
export type GetApiOrganizationByOrganizationIdUsersApiResponse =
  /** status 200 Success */ UserDto[];
export type GetApiOrganizationByOrganizationIdUsersApiArg = {
  organizationId: string;
};
export type GetApiPlantByPlantIdApiResponse =
  /** status 200 Success */ PlantDto;
export type GetApiPlantByPlantIdApiArg = {
  plantId: string;
};
export type PutApiPlantByPlantIdApiResponse =
  /** status 200 Success */ PlantDto;
export type PutApiPlantByPlantIdApiArg = {
  plantId: string;
  plantUpdateCommand: PlantUpdateCommand;
};
export type DeleteApiPlantByPlantIdApiResponse =
  /** status 200 Success */ PlantDto;
export type DeleteApiPlantByPlantIdApiArg = {
  plantId: string;
};
export type GetApiPlantGetAllApiResponse =
  /** status 200 Success */ PlantInfoDto[];
export type GetApiPlantGetAllApiArg = void;
export type PostApiPlantApiResponse = /** status 201 Created */ PlantDto;
export type PostApiPlantApiArg = {
  plantCreateCommand: PlantCreateCommand;
};
export type GetApiPotByPotIdApiResponse = /** status 200 Success */ PotDto;
export type GetApiPotByPotIdApiArg = {
  potId: string;
};
export type PostApiPotByPotIdAdditionalPotLogApiResponse =
  /** status 201 Created */ string;
export type PostApiPotByPotIdAdditionalPotLogApiArg = {
  potId: string;
  additionalPotLogCreateCommand: AdditionalPotLogCreateCommand;
};
export type PutApiPotByPotIdAdditionalPotLogAndAdditionalLogIdApiResponse =
  /** status 201 Created */ undefined;
export type PutApiPotByPotIdAdditionalPotLogAndAdditionalLogIdApiArg = {
  potId: string;
  additionalLogId: string;
  additionalPotLogUpdateCommand: AdditionalPotLogUpdateCommand;
};
export type PostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadApiResponse =
  /** status 201 Created */ undefined;
export type PostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadApiArg = {
  potId: string;
  additionalLogId: string;
  body: {
    File?: Blob;
  };
};
export type PostApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUploadApiResponse =
  /** status 201 Created */ undefined;
export type PostApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUploadApiArg =
  {
    potId: string;
    additionalLogId: string;
    additionalPotLogFileUploadMobileRequest: AdditionalPotLogFileUploadMobileRequest;
  };
export type PutApiPotByPotIdPotPlantedPlantApiResponse =
  /** status 200 Success */ undefined;
export type PutApiPotByPotIdPotPlantedPlantApiArg = {
  potId: string;
  potPlantedPlantCommand: PotPlantedPlantCommand;
};
export type PutApiPotByPotIdPotHarvestPlantApiResponse =
  /** status 201 Created */ undefined;
export type PutApiPotByPotIdPotHarvestPlantApiArg = {
  potId: string;
};
export type GetApiReadingsGetLatestPotReadingByPotIdApiResponse =
  /** status 200 OK */ PotReadingsDto;
export type GetApiReadingsGetLatestPotReadingByPotIdApiArg = {
  /** Id of the pot. */
  potId: string;
};
export type GetApiReadingsGetPotReadingsByPotIdApiResponse =
  /** status 200 OK */ PotReadingsDto;
export type GetApiReadingsGetPotReadingsByPotIdApiArg = {
  /** Id of the pot. */
  potId: string;
};
export type GetApiReadingsGetPotReadingsByDateByPotIdApiResponse =
  /** status 200 OK */ PotReadingsDto;
export type GetApiReadingsGetPotReadingsByDateByPotIdApiArg = {
  /** Id of the pot. */
  potId: string;
  /** Date of the readings. */
  date?: string;
};
export type GetApiReadingsGetLatestHubReadingByHubIdApiResponse =
  /** status 200 OK */ HubReadingsDto;
export type GetApiReadingsGetLatestHubReadingByHubIdApiArg = {
  /** Id of the hub. */
  hubId: string;
};
export type GetApiReadingsGetHubReadingByHubIdApiResponse =
  /** status 200 OK */ HubReadingsDto;
export type GetApiReadingsGetHubReadingByHubIdApiArg = {
  /** Id of the hub. */
  hubId: string;
};
export type GetApiReadingsGenerateHubReadingsZipByHubIdApiResponse =
  /** status 200 Returns zip file stream. */ Stream;
export type GetApiReadingsGenerateHubReadingsZipByHubIdApiArg = {
  /** The hub ID. */
  hubId: string;
  /** Time zone of the client (Europe/Zagreb). */
  timeZone?: string;
};
export type GetApiReadingsGetHubReadingByDateByHubIdApiResponse =
  /** status 200 OK */ HubReadingsDto;
export type GetApiReadingsGetHubReadingByDateByHubIdApiArg = {
  /** Id of the hub. */
  hubId: string;
  /** Date. */
  date?: string;
};
export type GetApiRolesGetAllApiResponse = /** status 200 Success */ RoleDto[];
export type GetApiRolesGetAllApiArg = void;
export type GetApiRolesGetMyApiResponse = /** status 200 Success */ RoleDto[];
export type GetApiRolesGetMyApiArg = void;
export type GetHealthApiResponse = /** status 200 Success */ string;
export type GetHealthApiArg = void;
export type GetAuthenticatedApiResponse = /** status 200 Success */ string;
export type GetAuthenticatedApiArg = void;
export type GetPermissionsApiResponse = /** status 200 Success */ {
  [key: string]: boolean;
};
export type GetPermissionsApiArg = void;
export type PostTestPushNotificationApiResponse = unknown;
export type PostTestPushNotificationApiArg = {
  firebaseNotificationRequest: FirebaseNotificationRequest;
};
export type GetApiUserByUserIdApiResponse = /** status 200 Success */ UserDto;
export type GetApiUserByUserIdApiArg = {
  userId: string;
};
export type PutApiUserByUserIdApiResponse = /** status 200 Success */ UserDto;
export type PutApiUserByUserIdApiArg = {
  userId: string;
  userUpdateCommand: UserUpdateCommand;
};
export type PostApiUserGetPaginatedApiResponse =
  /** status 200 Success */ UserDtoPaginatedData;
export type PostApiUserGetPaginatedApiArg = {
  paginatedUserFieldsEnumPaginatedRequest: PaginatedUserFieldsEnumPaginatedRequest;
};
export type PostApiUserApiResponse =
  /** status 201 Created */ UserCreateCommandResponse;
export type PostApiUserApiArg = {
  userCreateCommand: UserCreateCommand;
};
export type PutApiUserStudentByUserIdApiResponse =
  /** status 200 Success */ UserDto;
export type PutApiUserStudentByUserIdApiArg = {
  userId: string;
  userUpdateStudentCommand: UserUpdateStudentCommand;
};
export type PutApiUserByUserIdResetPasswordApiResponse =
  /** status 200 Success */ boolean;
export type PutApiUserByUserIdResetPasswordApiArg = {
  userId: string;
  userResetStudentPasswordCommand: UserResetStudentPasswordCommand;
};
export type PostApiUserByUserIdContactApiResponse =
  /** status 200 Success */ boolean;
export type PostApiUserByUserIdContactApiArg = {
  userId: string;
  userAddContactCommand: UserAddContactCommand;
};
export type PutApiUserByUserIdContactAndContactIdApiResponse =
  /** status 200 Success */ boolean;
export type PutApiUserByUserIdContactAndContactIdApiArg = {
  userId: string;
  contactId: string;
  userUpdateContactCommand: UserUpdateContactCommand;
};
export type DeleteApiUserByUserIdContactAndContactIdApiResponse =
  /** status 200 Success */ boolean;
export type DeleteApiUserByUserIdContactAndContactIdApiArg = {
  userId: string;
  contactId: string;
};
export type LoggedInUserDto = {
  id: string;
  email: string;
  username: string;
  token: string;
  refreshToken?: string | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type UserRegistrationCommand = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  normalizedEmail: string;
  password: string;
  rememberMe: boolean;
};
export type ErrorInfoDto = {
  statusCode: number;
  message: string;
};
export type CustomProblemDetailsResponse = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors: ErrorInfoDto[];
  [key: string]: any;
};
export type UserLoginCommand = {
  email: string;
  password: string;
  rememberMe: boolean;
  deviceFirebaseToken?: string | null;
};
export type RefreshTokenCommand = {
  refreshToken: string;
};
export type UserForgotPasswordCommand = {
  email: string;
};
export type UserResetPasswordCommand = {
  userId: string;
  password: string;
  token: string;
};
export type UserLogoutCommand = {
  refreshToken: string;
  unsetDeviceToken?: boolean | null;
};
export type DeviceCredentials = {
  primaryKey: string;
  secondaryKey: string;
  deviceHubHostname: string;
};
export type DeviceRegistrationDto = {
  hubId: string;
  deviceId: string;
  credentials: DeviceCredentials;
};
export type DeviceCreateCommand = {
  gardenId: string;
  name: string;
};
export type PlantDto = {
  id: string;
  name: string;
  nameLatin?: string | null;
  nameEnglish?: string | null;
  picturePaths?: string | null;
  growthTempMin: number;
  growthTempMax?: number | null;
  waterCapacityMin: number;
  waterCapacityMax?: number | null;
  photoPeriodMin: number;
  photoPeriodMax?: number | null;
  plantDensityMin: number;
  plantDensityMax?: number | null;
  timeToHarvestMin: number;
  timeToHarvestMax?: number | null;
  nutrientNeedN: number;
  nutrientNeedP205: number;
  nutrientNeedK20: number;
  nutrientRatioN: number;
  nutrientRatioP205: number;
  nutrientRatioK20: number;
  isEnabled: boolean;
};
export type BaseRoleEnum =
  | "Default"
  | "Admin"
  | "KlaraManager"
  | "User"
  | "Teacher"
  | "Student";
export type RolePermissionEnum = "Default" | "Allow" | "Deny";
export type RolePermissionDto = {
  id: string;
  roleId: string;
  permissionName: string;
  value: RolePermissionEnum;
};
export type RoleDto = {
  id: string;
  name: string;
  isAdmin: boolean;
  baseRole: BaseRoleEnum;
  rolePermissions: RolePermissionDto[];
};
export type UserDto = {
  id: string;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  roles?: RoleDto[] | null;
  dateOfBirth: string;
  joinedAt: string;
  isEnabled: boolean;
};
export type AdditionalPotLogImageDto = {
  id: string;
  blobUrl: string;
};
export type AdditionalPotLogDto = {
  id: string;
  createdById: string;
  createdBy?: UserDto;
  ph?: number | null;
  ec?: number | null;
  height?: number | null;
  numberOfLeaves?: number | null;
  numberOfFruits?: number | null;
  observation?: string | null;
  timestamp: string;
  additionalPotLogImages: AdditionalPotLogImageDto[];
};
export type PotDto = {
  id: string;
  hubId: string;
  plantId?: string | null;
  plant?: PlantDto;
  index: number;
  logs: AdditionalPotLogDto[];
  plantsDate: string;
  isAutomaticControlEnabled: boolean;
};
export type HubDto = {
  id: string;
  name: string;
  setupCompleted: boolean;
  createdAt: string;
  lightOnTime: string;
  lightOffTime: string;
  pots: PotDto[];
};
export type TankType =
  | "Unknown"
  | "Water"
  | "NutrientsP20N20K20"
  | "NutrientsP16N8K23"
  | "NutrientsP15N30K15";
export type ManualPumpActionCommand = {
  potId: string;
  tankType: TankType;
  quantity?: number | null;
};
export type ManualLightActionCommand = {
  activate: boolean;
};
export type DeviceTankFilledCommand = {
  tankType: TankType;
};
export type DeviceSetLightScheduleCommand = {
  lightOnTime: string;
  lightOffTime: string;
};
export type ClearDeviceSettingsCommand = object;
export type GardenDto = {
  id: string;
  name: string;
  organizationId: string;
  installationTimeStamp: string;
  hubs?: HubDto[] | null;
};
export type GardenUpdateCommand = {
  name: string;
};
export type GardenCreateCommand = {
  organizationId: string;
  name: string;
};
export type UserGardenAccessDto = {
  userId: string;
  user: UserDto;
};
export type AddUsersAccessCommand = {
  userIds: string[];
};
export type RemoveUsersAccessCommand = {
  userIds: string[];
};
export type OrganizationDto = {
  id: string;
  name: string;
  userContactId: string;
  userContact: UserDto;
  klaraContactId: string;
  klaraContact: UserDto;
};
export type OrganizationUpdateCommand = {
  name: string;
  userContactId: string;
  klaraContactId: string;
};
export type OrganizationCreateCommand = {
  name: string;
  userContactId: string;
  klaraContactId: string;
};
export type UserCreateCommandResponse = {
  id: string;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  joinedAt: string;
};
export type UserCreateCommand = {
  organizationId?: string | null;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  roleId: string;
  dateOfBirth?: string | null;
  expiresAt?: string | null;
  isEnabled: boolean;
};
export type BulkCreateStudentsResponseDto = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
};
export type BulkCreateStudentsResponse = {
  createdUsers: BulkCreateStudentsResponseDto[];
};
export type BulkCreateStudentRecord = {
  firstName: string;
  lastName: string;
};
export type BulkCreateStudentsCommand = {
  expiresAt?: string | null;
  students: BulkCreateStudentRecord[];
};
export type PlantUpdateCommand = {
  name: string;
  nameLatin?: string | null;
  nameEnglish?: string | null;
  picturePaths?: string | null;
  growthTempMin: number;
  growthTempMax?: number | null;
  waterCapacityMin: number;
  waterCapacityMax?: number | null;
  photoPeriodMin: number;
  photoPeriodMax?: number | null;
  plantDensityMin: number;
  plantDensityMax?: number | null;
  timeToHarvestMin: number;
  timeToHarvestMax?: number | null;
  nutrientNeedN: number;
  nutrientNeedP205: number;
  nutrientNeedK20: number;
  nutrientRatioN: number;
  nutrientRatioP205: number;
  nutrientRatioK20: number;
  isEnabled: boolean;
};
export type PlantInfoDto = {
  id: string;
  name: string;
  nameLatin?: string | null;
  nameEnglish?: string | null;
  isEnabled: boolean;
};
export type PlantCreateCommand = {
  name: string;
  nameLatin?: string | null;
  nameEnglish?: string | null;
  picturePaths?: string | null;
  growthTempMin: number;
  growthTempMax?: number | null;
  waterCapacityMin: number;
  waterCapacityMax?: number | null;
  photoPeriodMin: number;
  photoPeriodMax?: number | null;
  plantDensityMin: number;
  plantDensityMax?: number | null;
  timeToHarvestMin: number;
  timeToHarvestMax?: number | null;
  nutrientNeedN: number;
  nutrientNeedP205: number;
  nutrientNeedK20: number;
  nutrientRatioN: number;
  nutrientRatioP205: number;
  nutrientRatioK20: number;
  isEnabled: boolean;
};
export type AdditionalPotLogCreateCommand = {
  ph?: number | null;
  ec?: number | null;
  height?: number | null;
  numberOfLeaves?: number | null;
  numberOfFruits?: number | null;
  observation?: string | null;
};
export type AdditionalPotLogUpdateCommand = {
  ph?: number | null;
  ec?: number | null;
  height?: number | null;
  numberOfLeaves?: number | null;
  numberOfFruits?: number | null;
  observation?: string | null;
};
export type AdditionalPotLogFileUploadMobileRequest = {
  name: string;
  contentType: string;
  base64: string;
};
export type PotPlantedPlantCommand = {
  plantId?: string | null;
};
export type PumpControlReadingDto = {
  id: string;
  tankType: TankType;
  timestamp: string;
  quantity?: number | null;
};
export type PumpControlRequestDto = {
  id: string;
  tankType: TankType;
  timestamp: string;
  createdById: string;
  createdBy?: UserDto;
};
export type SensorType =
  | "Unknown"
  | "Temperature"
  | "Humidity"
  | "Light"
  | "SoilMoisture";
export type PotSensorReadingDto = {
  id: string;
  sensorType: SensorType;
  value: number;
  timestamp: string;
};
export type PotHarvestLogDto = {
  id: string;
  plantName: string;
  timestamp: string;
  createdBy?: UserDto;
};
export type PotReadingsDto = {
  potId: string;
  index: number;
  pumpControlReadings: PumpControlReadingDto[];
  pumpControlRequests: PumpControlRequestDto[];
  potSensorReadings: PotSensorReadingDto[];
  additionalPotLogs: AdditionalPotLogDto[];
  harvestLogs: PotHarvestLogDto[];
};
export type TankReadingDto = {
  id: string;
  hubId: string;
  level: number;
  capacity: number;
  capacityThreshold: number;
  tankType: TankType;
  timestamp: string;
};
export type HubSensorReadingDto = {
  id: string;
  hubId: string;
  sensorType: SensorType;
  value: number;
  timestamp: string;
};
export type LightControlReadingDto = {
  id: string;
  hubId: string;
  isLightOn: boolean;
  timestamp: string;
};
export type HubReadingsDto = {
  tankReadings: TankReadingDto[];
  hubSensorReadings: HubSensorReadingDto[];
  lightControlReadings: LightControlReadingDto[];
};
export type Stream = {
  canRead: boolean;
  canWrite: boolean;
  canSeek: boolean;
  canTimeout: boolean;
  length: number;
  position: number;
  readTimeout: number;
  writeTimeout: number;
};
export type FirebaseNotificationRequest = {
  token: string;
  title: string;
  body: string;
  imageUrl?: string | null;
};
export type UserUpdateCommand = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  roleId: string;
  dateOfBirth?: string | null;
  expiresAt?: string | null;
  isEnabled: boolean;
};
export type UserDtoPaginatedData = {
  page: number;
  pageSize: number;
  total: number;
  data: UserDto[];
};
export type PaginatedUserFieldsEnum =
  | "Username"
  | "Email"
  | "FirstName"
  | "LastName"
  | "DateOfBirth"
  | "JoinedAt"
  | "Role"
  | "UserType"
  | "Organization";
export type PaginatedUserFieldsEnumFilterByRecord = {
  field: PaginatedUserFieldsEnum;
  value: string;
};
export type SortEnum = "Asc" | "Desc";
export type PaginatedUserFieldsEnumOrderByRecord = {
  field: PaginatedUserFieldsEnum;
  sort?: SortEnum;
};
export type PaginatedUserFieldsEnumPaginatedRequest = {
  page: number;
  pageSize: number;
  search?: string | null;
  filterBy?: PaginatedUserFieldsEnumFilterByRecord[] | null;
  orderBy?: PaginatedUserFieldsEnumOrderByRecord[] | null;
};
export type UserUpdateStudentCommand = {
  username: string;
  firstName: string;
  lastName: string;
  expiresAt?: string | null;
  isEnabled: boolean;
};
export type UserResetStudentPasswordCommand = {
  newPassword: string;
};
export type UserAddContactCommand = {
  address: string;
  phone: string;
};
export type UserUpdateContactCommand = {
  address: string;
  phone: string;
};
export const {
  usePostApiAuthenticationRegisterMutation,
  usePostApiAuthenticationLoginMutation,
  usePostApiAuthenticationRefreshTokenMutation,
  usePostApiAuthenticationForgotPasswordMutation,
  usePostApiAuthenticationResetPasswordMutation,
  usePostApiAuthenticationLogoutMutation,
  usePostApiDeviceRegisterMutation,
  useGetApiDeviceByHubIdQuery,
  useLazyGetApiDeviceByHubIdQuery,
  useDeleteApiDeviceByHubIdMutation,
  usePutApiDeviceByHubIdMutation,
  useGetApiDeviceByHubIdDeviceStatusQuery,
  useLazyGetApiDeviceByHubIdDeviceStatusQuery,
  usePostApiDeviceByHubIdManualPumpActionMutation,
  usePostApiDeviceByHubIdManualLightActionMutation,
  usePostApiDeviceByHubIdForceNewSensorReadingsMutation,
  usePostApiDeviceByHubIdTankFilledMutation,
  usePostApiDeviceByHubIdSetLightScheduleMutation,
  usePostApiDeviceByHubIdClearDeviceSettingsMutation,
  useGetApiGardenGetAllQuery,
  useLazyGetApiGardenGetAllQuery,
  useGetApiGardenByGardenIdQuery,
  useLazyGetApiGardenByGardenIdQuery,
  usePutApiGardenByGardenIdMutation,
  usePostApiGardenMutation,
  useGetApiGardenByGardenIdUsersQuery,
  useLazyGetApiGardenByGardenIdUsersQuery,
  usePostApiGardenByGardenIdUsersMutation,
  useDeleteApiGardenByGardenIdUsersMutation,
  useGetApiOrganizationByOrganizationIdQuery,
  useLazyGetApiOrganizationByOrganizationIdQuery,
  usePutApiOrganizationByOrganizationIdMutation,
  useGetApiOrganizationGetAllQuery,
  useLazyGetApiOrganizationGetAllQuery,
  useGetApiOrganizationGetMyQuery,
  useLazyGetApiOrganizationGetMyQuery,
  usePostApiOrganizationMutation,
  usePostApiOrganizationByOrganizationIdCreateUserMutation,
  usePostApiOrganizationByOrganizationIdBulkCreateStudentsMutation,
  useGetApiOrganizationByOrganizationIdUsersQuery,
  useLazyGetApiOrganizationByOrganizationIdUsersQuery,
  useGetApiPlantByPlantIdQuery,
  useLazyGetApiPlantByPlantIdQuery,
  usePutApiPlantByPlantIdMutation,
  useDeleteApiPlantByPlantIdMutation,
  useGetApiPlantGetAllQuery,
  useLazyGetApiPlantGetAllQuery,
  usePostApiPlantMutation,
  useGetApiPotByPotIdQuery,
  useLazyGetApiPotByPotIdQuery,
  usePostApiPotByPotIdAdditionalPotLogMutation,
  usePutApiPotByPotIdAdditionalPotLogAndAdditionalLogIdMutation,
  usePostApiPotByPotIdAdditionalPotLogAndAdditionalLogIdUploadMutation,
  usePostApiPotByPotIdAdditionalPotLogMobileUploadAndAdditionalLogIdUploadMutation,
  usePutApiPotByPotIdPotPlantedPlantMutation,
  usePutApiPotByPotIdPotHarvestPlantMutation,
  useGetApiReadingsGetLatestPotReadingByPotIdQuery,
  useLazyGetApiReadingsGetLatestPotReadingByPotIdQuery,
  useGetApiReadingsGetPotReadingsByPotIdQuery,
  useLazyGetApiReadingsGetPotReadingsByPotIdQuery,
  useGetApiReadingsGetPotReadingsByDateByPotIdQuery,
  useLazyGetApiReadingsGetPotReadingsByDateByPotIdQuery,
  useGetApiReadingsGetLatestHubReadingByHubIdQuery,
  useLazyGetApiReadingsGetLatestHubReadingByHubIdQuery,
  useGetApiReadingsGetHubReadingByHubIdQuery,
  useLazyGetApiReadingsGetHubReadingByHubIdQuery,
  useGetApiReadingsGenerateHubReadingsZipByHubIdQuery,
  useLazyGetApiReadingsGenerateHubReadingsZipByHubIdQuery,
  useGetApiReadingsGetHubReadingByDateByHubIdQuery,
  useLazyGetApiReadingsGetHubReadingByDateByHubIdQuery,
  useGetApiRolesGetAllQuery,
  useLazyGetApiRolesGetAllQuery,
  useGetApiRolesGetMyQuery,
  useLazyGetApiRolesGetMyQuery,
  useGetHealthQuery,
  useLazyGetHealthQuery,
  useGetAuthenticatedQuery,
  useLazyGetAuthenticatedQuery,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
  usePostTestPushNotificationMutation,
  useGetApiUserByUserIdQuery,
  useLazyGetApiUserByUserIdQuery,
  usePutApiUserByUserIdMutation,
  usePostApiUserGetPaginatedMutation,
  usePostApiUserMutation,
  usePutApiUserStudentByUserIdMutation,
  usePutApiUserByUserIdResetPasswordMutation,
  usePostApiUserByUserIdContactMutation,
  usePutApiUserByUserIdContactAndContactIdMutation,
  useDeleteApiUserByUserIdContactAndContactIdMutation,
} = injectedRtkApi;
