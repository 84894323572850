// @mui
import {styled} from '@mui/material/styles';
import {Box, List, ListSubheader} from '@mui/material';
// type
import {NavSectionProps} from '../type';
//
import {NavListRoot} from './NavList';
import RequireSuperAdmin from "../../component-guard/RequireSuperAdmin";
import RequireRole from "../../component-guard/RequireRole";
import RequirePermission from "../../component-guard/RequirePermission";
import {Fragment} from "react";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
))(({theme}) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
                                               navConfig,
                                               isCollapse = false,
                                               ...other
                                           }: NavSectionProps) {
    return (
        <Box {...other}>
            {navConfig.map((group) => (
                <Fragment key={group.subheader}>
                    <RequireSuperAdmin requireAdmin={group.requireAdmin}>
                        <RequireRole role={group.requireRole}>
                            <RequirePermission permission={group.requirePermission}>
                                <List disablePadding sx={{px: 2}}>
                                    {/*
                                    //@ts-ignore*/}
                                    <ListSubheaderStyle
                                        sx={{
                                            ...(isCollapse && {
                                                opacity: 0,
                                            }),
                                        }}
                                    >
                                        {group.subheader}
                                    </ListSubheaderStyle>

                                    {group.items.map((list) => (
                                        <NavListRoot key={list.title} list={list} isCollapse={isCollapse}/>
                                    ))}
                                </List>
                            </RequirePermission>
                        </RequireRole>
                    </RequireSuperAdmin>
                </Fragment>
            ))}
        </Box>
    );
}
