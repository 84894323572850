import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import {persistConfig} from "./persistConfig";
import { api } from "../services/api";
import userStore from "./stores/UserStore";
import generalStore from "./stores/GeneralStore";

const reducers = combineReducers({
    user: userStore,
    general: generalStore,
    api: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(api.middleware);

        return middlewares;
    },
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

// export const useAppDispatch: () => AppDispatch = useDispatch;

type AppState = ReturnType<typeof store.getState>;
const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export {useAppSelector, store, persistor };
export type { AppState };