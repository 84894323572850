import {PumpControlReadingDto} from "../../../services/klaraApi";
import {useTranslation} from "react-i18next";
import {Paper, Stack, Typography} from "@mui/material";
import moment from "moment/moment";
import { PotReading } from "../../../utils/unifiedReadings";

export const RenderPumpControlReadingLog: React.FC<PumpControlReadingDto & PotReading> = ({timestamp, potIndex, tankType}) => {
    const {t} = useTranslation();

    let tankTypeName = t(`events.tankTypes.${tankType}`);
    return (
        <Paper elevation={1} sx={{m: 2, p: 2}}>
            <Stack
                spacing={2}
                justifyContent={"space-between"}
            >
                <Typography variant="body1" textAlign={"left"}>
                    {t("events.messages.pumpAction", {tankType: tankTypeName, index: potIndex})}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                    {moment.utc(timestamp).local().format("HH:mm DD-MM-YYYY")}
                </Typography>
            </Stack>
        </Paper>
    );
};

