import {BaseRoleEnum, useGetApiRolesGetMyQuery} from "../../services/klaraApi";
import checkBaseRole from "./checkBaseRole";
import {ReactNode} from "react";

type RequireRoleProps = {
    role?: BaseRoleEnum;
    children: ReactNode;
};

export default function RequireRole(props: RequireRoleProps) {
    const {data} = useGetApiRolesGetMyQuery();

    if(!props.role) return <>{props.children}</>;

    return checkBaseRole(props.role, data ?? []) ? <>{props.children}</> : <></>;
}