import {useParams} from "react-router-dom";
import Page from "../../../components/Page";
import {
    AdditionalPotLogDto,
    useGetApiDeviceByHubIdQuery,
    useGetApiGardenByGardenIdQuery,
    useGetApiPlantByPlantIdQuery,
    useGetApiPotByPotIdQuery,
    useGetApiReadingsGetPotReadingsByDateByPotIdQuery, useLazyGetApiReadingsGenerateHubReadingsZipByHubIdQuery
} from "../../../services/klaraApi";
import RequirePermission from "../../../components/component-guard/RequirePermission";
import {Permissions} from "../../../utils/permissions";
import {Box, Button, Container, IconButton, Link, Stack, Tab} from "@mui/material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {useTranslation} from "react-i18next";
import {useCallback, useMemo, useState} from "react";
import moment from "moment";
import {mapReadings} from "../../../utils/unifiedReadings";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {RenderAnyUnifiedPotReading} from "./RenderAnyUnifiedPotReading";
import Iconify from "../../../components/Iconify";

export const PotDetails = () => {
    const {id, hubId, potId} = useParams<{ id: string, hubId: string, potId: string }>();
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
    const [tab, setTab] = useState('logs');

    const {data: myGarden} = useGetApiGardenByGardenIdQuery(
        {
            gardenId: id ?? "",
        },
        {
            skip: !id,
        }
    );

    const {data: hubData} = useGetApiDeviceByHubIdQuery({
        hubId: hubId ?? ""
    }, {
        skip: !hubId
    });

    const {data: potData} = useGetApiPotByPotIdQuery({
        potId: potId ?? ""
    }, {
        skip: !potId
    });

    const {data: potReadings} = useGetApiReadingsGetPotReadingsByDateByPotIdQuery({
        potId: potId ?? "",
        date: selectedDate.format("YYYY-MM-DD")
    }, {
        skip: !potId
    });

    const sortedAndMappedLogData = useMemo(() => {
        if (!potReadings) return [];

        return mapReadings(potReadings);
    }, [potReadings]);

    const {data: plantData} = useGetApiPlantByPlantIdQuery(
        {
            plantId: potData?.plantId ?? ""
        }, {
            skip: !potData?.plantId
        }
    );

    const {t, i18n} = useTranslation();

    return (
        <Page title={`${t('common.defaultPotName')} ${potData?.index ?? 0 + 1}`}>
            <RequirePermission permission={Permissions.Garden.Get} redirect>
                <Container maxWidth='xl'>
                    <HeaderBreadcrumbs
                        heading={`${t('common.defaultPotName')} ${potData?.index ?? 0 + 1}`}
                        links={[
                            { 
                                name: myGarden?.name ?? "",
                                href: PATH_DASHBOARD.gardenById(myGarden?.id ?? id ?? "") 
                            },
                            {
                                name: hubData?.name ?? t('common.defaultHubName'),
                                href: PATH_DASHBOARD.hubLogsById(hubId ?? "", id ?? "")
                            },
                            {name: `${t('common.defaultPotName')} ${potData?.index ?? 0 + 1}:` + " " + plantData?.name ?? ""}
                        ]}
                        action={
                            <Stack direction="row" spacing={2}>
                                <DatePicker
                                    label={t("garden.potDetails.date")}
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue ?? moment())}
                                />
                            </Stack>
                        }
                    />
                    <TabContext value={tab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider', my: 4, mx: 2}}>
                            <TabList onChange={(e, value) => setTab(value)}>
                                <Tab label={t("garden.potDetails.logs")} value="logs"/>
                                <Tab label={t("garden.potDetails.events")} value="events"/>
                            </TabList>
                        </Box>
                        <TabPanel value="logs">
                            <Box>
                                <Button variant="contained" color="primary" sx={{m: 2}}
                                        href={id && hubId && potId && PATH_DASHBOARD.newPotLog(potId, hubId, id)}>
                                    {t("garden.potDetails.addPotLog")}
                                </Button>
                                {
                                    sortedAndMappedLogData.filter(t => t.logType === "AdditionalPotLogDto").map((item) => (
                                        <RenderAnyUnifiedPotReading key={item.id} {...item} />
                                    )) || <Container>{t("events.noEvents")}</Container>
                                }
                            </Box>
                        </TabPanel>
                        <TabPanel value="events">
                            <Box>
                                {
                                    sortedAndMappedLogData.filter(t => t.logType !== "AdditionalPotLogDto").map((item) => (
                                        <RenderAnyUnifiedPotReading key={item.id} {...{...item, potIndex: potData?.index ?? 1}} />
                                    )) || <Container>{t("events.noEvents")}</Container>
                                }
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Container>
            </RequirePermission>
        </Page>
    )
}

