// icons
import {Icon, IconifyIcon} from '@iconify/react';
// @mui
import {Box, BoxProps, SxProps} from '@mui/material';
import {Theme} from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    sx?: SxProps<Theme>;
    icon: IconifyIcon | string;
}

export default function Iconify({icon, sx, ...other}: Props) {
    return <Box component={Icon} icon={icon} sx={{...sx}} {...other} />;
}
