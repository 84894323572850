import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    PlantCreateCommand,
    PlantUpdateCommand,
    useGetApiPlantByPlantIdQuery,
    usePostApiPlantMutation,
    usePutApiPlantByPlantIdMutation
} from "../../services/klaraApi";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { useCallback, useEffect } from "react";
import RHFTextField from "../../components/hook-form/RHFTextField";
import { useSnackbar } from "notistack";
import RHFSwitch from "../../components/hook-form/RHFSwitch";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { unitMap } from "../../utils/unitMap";


type PlantCreateEditDialogProps = {
    isReadOnly?: boolean,
    existingPlantId?: string | null;
    open: boolean;
    onClose: () => void;
}

const schema: yup.ObjectSchema<PlantCreateCommand & PlantUpdateCommand> = yup.object({
    name: yup.string().required(),
    nameLatin: yup.string().nullable(),
    nameEnglish: yup.string().nullable(),
    picturePaths: yup.string().nullable(),
    growthTempMin: yup.number().required(),
    growthTempMax: yup.number().nullable(),
    waterCapacityMin: yup.number().required(),
    waterCapacityMax: yup.number().nullable(),
    photoPeriodMin: yup.number().required(),
    photoPeriodMax: yup.number().nullable(),
    plantDensityMin: yup.number().required(),
    plantDensityMax: yup.number().nullable(),
    timeToHarvestMin: yup.number().required(),
    timeToHarvestMax: yup.number().nullable(),
    nutrientNeedN: yup.number().required(),
    nutrientNeedP205: yup.number().required(),
    nutrientNeedK20: yup.number().required(),
    nutrientRatioN: yup.number().required(),
    nutrientRatioP205: yup.number().required(),
    nutrientRatioK20: yup.number().required(),
    isEnabled: yup.boolean().required(),
}).required();

const defaultValues: PlantCreateCommand & PlantUpdateCommand = {
    name: "",
    nameLatin: "",
    nameEnglish: "",
    picturePaths: "",
    growthTempMin: 0,
    growthTempMax: 0,
    waterCapacityMin: 0,
    waterCapacityMax: 0,
    photoPeriodMin: 0,
    photoPeriodMax: 0,
    plantDensityMin: 0,
    plantDensityMax: 0,
    timeToHarvestMin: 0,
    timeToHarvestMax: 0,
    nutrientNeedN: 0,
    nutrientNeedP205: 0,
    nutrientNeedK20: 0,
    nutrientRatioN: 0,
    nutrientRatioP205: 0,
    nutrientRatioK20: 0,
    isEnabled: true,
}

const properties = [
    { name: "name", md: 4 },
    { name: "nameLatin", md: 4 },
    { name: "nameEnglish", md: 4 },
    { name: "growthTempMin", md: 3 },
    { name: "growthTempMax", md: 3 },
    { name: "waterCapacityMin", md: 3 },
    { name: "waterCapacityMax", md: 3 },
    { name: "photoPeriodMin", md: 3 },
    { name: "photoPeriodMax", md: 3 },
    { name: "plantDensityMin", md: 3 },
    { name: "plantDensityMax", md: 3 },
    { name: "timeToHarvestMin", md: 3 },
    { name: "timeToHarvestMax", md: 3 },
    { name: "nutrientNeedN", md: 3 },
    { name: "nutrientRatioN", md: 3 },
    { name: "nutrientNeedP205", md: 3 },
    { name: "nutrientRatioP205", md: 3 },
    { name: "nutrientNeedK20", md: 3 },
    { name: "nutrientRatioK20", md: 3 },
]

export const PlantCreateEditDialog = ({ open, onClose, existingPlantId, isReadOnly = false }: PlantCreateEditDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm<PlantCreateCommand>({ defaultValues, resolver: yupResolver(schema) });

    const { data, isLoading, isFetching } = useGetApiPlantByPlantIdQuery({ plantId: existingPlantId ?? "" }, {
        skip: !existingPlantId,
        refetchOnMountOrArgChange: true
    });
    const [createPlant, createPlantResult] = usePostApiPlantMutation();
    const [updatePlant, updatePlantResult] = usePutApiPlantByPlantIdMutation();

    const { t } = useTranslation();

    const handleSave = useCallback(async (values: PlantCreateCommand | PlantUpdateCommand) => {
        if (existingPlantId) {
            try {
                await updatePlant({ plantId: existingPlantId, plantUpdateCommand: values }).unwrap();
                enqueueSnackbar(t('plants.createEdit.updateSuccess'), { variant: "success" });
                onClose();
            } catch (e) {
                enqueueSnackbar(t('plants.createEdit.updateFail'), { variant: "error" });
            }
        } else {
            try {
                await createPlant({ plantCreateCommand: values }).unwrap();
                enqueueSnackbar(t('plants.createEdit.createSuccess'), { variant: "success" });
                onClose();
            } catch (e) {
                enqueueSnackbar(t('plants.createEdit.createFail'), { variant: "error" });
            }
        }
    }, [createPlant, enqueueSnackbar, t, existingPlantId, onClose, updatePlant]);

    useEffect(() => {
        if (existingPlantId) {
            if (data) {
                methods.reset(data);
            }
        } else {
            methods.reset(defaultValues);
        }
    }, [data, existingPlantId, methods.reset]);

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
            <Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth>
                {!isReadOnly && <DialogTitle>
                    {existingPlantId ? t('plants.createEdit.editTitle') : t('plants.createEdit.createTitle')}
                </DialogTitle>}
                {isReadOnly && <DialogTitle>
                    {t('plants.view')}
                </DialogTitle>}
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {
                            properties.map(property => (
                                <Grid item xs={12} md={property.md} key={property.name}>
                                    <RHFTextField name={property.name} label={unitMap[property.name] ? `${t(`plants.createEdit.${property.name}`)} / ${ t(`events.units.${unitMap[property.name]}`)}` : t(`plants.createEdit.${property.name}`) } disabled={isReadOnly} />
                                </Grid>
                            ))
                        }

                        {!isReadOnly && <Grid item xs={12} md={3}>
                            <RHFSwitch name={"isEnabled"} label={t('plants.createEdit.enabled')} />
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{isReadOnly ? t('common.close') : t('common.cancel')}</Button>
                    {!isReadOnly && <Button onClick={methods.handleSubmit((data) => handleSave(data))}>{t('common.save')}</Button>}
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
