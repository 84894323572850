import {createSlice} from "@reduxjs/toolkit";
import {revertAll} from "../actions/revertAll";

const initialState: GeneralState = {
    isNavbarOpen: true,
    isNavbarPinned: true,
    isNavbarHovered: false,

    language: "hr",
};

const slice = createSlice({
    name: "general",
    initialState,
    reducers: {
        setNavbarPinned: (state, {payload: {isNavbarPinned}}: GeneralPayload) => {
            state.isNavbarPinned = isNavbarPinned ?? false;
            state.isNavbarOpen = isNavbarPinned || state.isNavbarHovered;
        },
        setNavbarHovered: (state, {payload: {isNavbarHovered}}: GeneralPayload) => {
            state.isNavbarHovered = isNavbarHovered ?? false;
            state.isNavbarOpen = isNavbarHovered || state.isNavbarPinned;
        },

        setLanguage: (state, {payload: {language}}: GeneralPayload) => {
            state.language = language;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(revertAll, () => initialState);
    },
});

export const {setNavbarPinned, setNavbarHovered, setLanguage} = slice.actions;

export default slice.reducer;

export type GeneralState = {
    isNavbarOpen: boolean;
    isNavbarPinned: boolean;
    isNavbarHovered: boolean;

    language?: string;
};

type GeneralPayload = {
    payload: Partial<GeneralState>;
};
