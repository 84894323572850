import {useParams} from "react-router-dom";
import Page from "../../../components/Page";
import {
    GetApiReadingsGenerateHubReadingsZipByHubIdApiResponse,
    useGetApiDeviceByHubIdQuery,
    useGetApiGardenByGardenIdQuery,
    useGetApiPlantByPlantIdQuery,
    useGetApiPotByPotIdQuery, useGetApiReadingsGenerateHubReadingsZipByHubIdQuery,
    useGetApiReadingsGetHubReadingByDateByHubIdQuery,
    useGetApiReadingsGetLatestHubReadingByHubIdQuery,
    useGetApiReadingsGetPotReadingsByDateByPotIdQuery,
    useLazyGetApiReadingsGenerateHubReadingsZipByHubIdQuery
} from "../../../services/klaraApi";
import RequirePermission from "../../../components/component-guard/RequirePermission";
import {Permissions} from "../../../utils/permissions";
import {Box, Button, Container, Stack, Tab} from "@mui/material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {Pot} from "../../../sections/garden/Pot";
import {useTranslation} from "react-i18next";
import {useCallback, useMemo, useState} from "react";
import moment from "moment/moment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import {RenderAnyUnifiedPotReading} from "./RenderAnyUnifiedPotReading";
import {mapReadings} from "../../../utils/unifiedReadings";
import Iconify from "../../../components/Iconify";
import {useSnackbar} from "notistack";
import {api} from "../../../services/api";
import {useAppSelector} from "../../../store";
import {Config} from "../../../config";

export const HubDetails = () => {
    const {id, hubId} = useParams<{ id: string, hubId: string }>();
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
    const [tab, setTab] = useState('logs');

    const {data: myGarden} = useGetApiGardenByGardenIdQuery(
        {
            gardenId: id ?? "",
        },
        {
            skip: !id,
        }
    );

    const {data: hubData} = useGetApiDeviceByHubIdQuery({
        hubId: hubId ?? ""
    }, {
        skip: !hubId
    });

    const {data: potReadings1} = useGetApiReadingsGetPotReadingsByDateByPotIdQuery({
        potId: hubData?.pots[0]?.id ?? "",
        date: selectedDate.format("YYYY-MM-DD")
    }, {skip: !hubData, refetchOnMountOrArgChange:true});
    
    const {data: potReadings2} = useGetApiReadingsGetPotReadingsByDateByPotIdQuery({
        potId: hubData?.pots[1]?.id ?? "",
        date: selectedDate.format("YYYY-MM-DD")
    }, {skip: !hubData, refetchOnMountOrArgChange:true});

    const {data: potReadings3} = useGetApiReadingsGetPotReadingsByDateByPotIdQuery({
        potId: hubData?.pots[2]?.id ?? "",
        date: selectedDate.format("YYYY-MM-DD")
    }, {skip: !hubData, refetchOnMountOrArgChange:true});

    const {data: hubReadings} = useGetApiReadingsGetHubReadingByDateByHubIdQuery({
        hubId: hubId ?? "",
        date: selectedDate.format("YYYY-MM-DD")
    }, {
        skip: !hubId
    });

    const [isExporting, setIsExporting] = useState(false);

    const sortedAndMappedLogData = useMemo(() => {
        if (!hubReadings) return [];

        return mapReadings(hubReadings);
    }, [hubReadings]);

    const sortedAndMappedPotData = useMemo(() => {
        if (!potReadings1 || !potReadings2 || !potReadings3) return [];

        return [
            ...mapReadings(potReadings1, hubData),
            ...mapReadings(potReadings2, hubData),
            ...mapReadings(potReadings3, hubData),
        ].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    }, [potReadings1, potReadings2, potReadings3]);

    const {t, i18n} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const authToken = useAppSelector(state => state.user.token)
    const handleExport = useCallback(async () => {
        setIsExporting(true);
        try{
            const endpoint = `${Config.API_URL}api/Readings/GenerateHubReadingsZip/${hubId}?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/zip",
                    "Authorization": `Bearer ${authToken}`
                }
            });

            
            var blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `SmartWall-${hubId}.zip`;
            a.click();
        } catch (e) {
            console.log(e);
            enqueueSnackbar(t("garden.potDetails.exportError"), {variant: "error"});
        }
        setIsExporting(false);
    }, []);

    return (
        <Page title={hubData?.name ?? ""}>
            <RequirePermission permission={Permissions.Garden.Get} redirect>
                <Container maxWidth='xl'>
                    <HeaderBreadcrumbs
                        heading={hubData?.name ?? ""}
                        links={[
                            {name: myGarden?.name ?? "", href: PATH_DASHBOARD.gardenById(id ?? "")},
                        ]}
                        action={
                            <Stack direction="row" spacing={2}>
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleExport}
                                    loading={isExporting}>
                                 {!isExporting && <Iconify icon={"mdi:download"} width={20} height={20} marginRight={1}/>}
                                 {t("garden.potDetails.export")}
                                </LoadingButton>
                             
                                <DatePicker
                                    label={t("garden.potDetails.date")}
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue ?? moment())}
                                />
                            </Stack>
                        }
                    />
                    <TabContext value={tab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider', my: 4, mx: 2}}>
                            <TabList onChange={(e, value) => setTab(value)}>
                                <Tab label={t("garden.potDetails.logs")} value="logs"/>
                                <Tab label={t("garden.potDetails.events")} value="events"/>
                            </TabList>
                        </Box>
                        <TabPanel value="logs">
                            <Box>
                                {
                                    sortedAndMappedPotData.filter(t => t.logType === "AdditionalPotLogDto").map((item) => (
                                        <RenderAnyUnifiedPotReading key={item.id} {...item} />
                                    )) || <Container>{t("events.noEvents")}</Container>
                                }
                            </Box>
                        </TabPanel>
                        <TabPanel value="events">
                            <Box>
                                {
                                    [...sortedAndMappedPotData.filter(t => t.logType !== "AdditionalPotLogDto"), ...sortedAndMappedLogData].map((item) => (
                                        <RenderAnyUnifiedPotReading key={item.id} {...item} />
                                    )) || <Container>{t("events.noEvents")}</Container>
                                }
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Container>
            </RequirePermission>
        </Page>
    )
}
