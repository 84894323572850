import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Stack
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useDeleteApiUserByUserIdContactAndContactIdMutation,
    useGetApiOrganizationGetAllQuery,
    useGetApiRolesGetAllQuery,
    useGetApiUserByUserIdQuery,
    usePostApiUserByUserIdContactMutation,
    usePostApiUserMutation,
    usePutApiUserByUserIdContactAndContactIdMutation,
    usePutApiUserByUserIdMutation,
    UserAddContactCommand,
    UserCreateCommand
} from "../../services/klaraApi";
import { useFieldArray, useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { useCallback, useEffect } from "react";
import RHFTextField from "../../components/hook-form/RHFTextField";
import { useSnackbar } from "notistack";
import RHFSwitch from "../../components/hook-form/RHFSwitch";
import RHFSelect from "../../components/hook-form/RHFSelect";
import Iconify from "../../components/Iconify";
import { useTranslation } from "react-i18next";


type UserCreateEditDialogProps = {
    existingUserId?: string | null;
    open: boolean;
    onClose: () => void;
}

type FormData = UserCreateCommand & {
    contacts: Array<UserAddContactCommand & { id?: string | null }>
    contactsToDelete?: Array<string>;
};

const schema: yup.ObjectSchema<FormData> = yup.object({
    email: yup.string().required(),
    username: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    roleId: yup.string().required(),
    organizationId: yup.string().optional().nullable(),
    dateOfBirth: yup.string().optional().nullable(),
    expiresAt: yup.string().optional().nullable(),
    isEnabled: yup.boolean().required(),
    contacts: yup.array().of(yup.object({
        id: yup.string().optional().nullable(),
        phone: yup.string().ensure(),
        address: yup.string().ensure(),
    })).required(),
    contactsToDelete: yup.array().of(yup.string().required()).ensure(),
}).required();

const defaultValues: FormData = {
    email: "",
    username: "",
    firstName: "",
    lastName: "",
    roleId: "",
    organizationId: null,
    dateOfBirth: null,
    expiresAt: null,
    isEnabled: true,
    contacts: [{
        id: null,
        phone: "",
        address: "",
    }],
    contactsToDelete: [],
}

export const UserCreateEditDialog = ({ open, onClose, existingUserId }: UserCreateEditDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm<FormData>({ defaultValues, resolver: yupResolver(schema) });
    const { fields, append, remove } = useFieldArray({ control: methods.control, name: "contacts" });


    const { data, isLoading, isFetching } = useGetApiUserByUserIdQuery({ userId: existingUserId ?? "" }, {
        skip: !existingUserId,
        refetchOnMountOrArgChange: true
    });
    const [createUser, createUserResult] = usePostApiUserMutation();
    const [updateUser, updateUserResult] = usePutApiUserByUserIdMutation();

    const [addUserContact, addUserContactResult] = usePostApiUserByUserIdContactMutation();
    const [updateContact, updateContactResult] = usePutApiUserByUserIdContactAndContactIdMutation();
    const [deleteContact, deleteContactResult] = useDeleteApiUserByUserIdContactAndContactIdMutation();

    const { data: roles } = useGetApiRolesGetAllQuery(undefined, { refetchOnMountOrArgChange: true });
    const { data: organizations } = useGetApiOrganizationGetAllQuery(undefined, { refetchOnMountOrArgChange: true });

    const { t } = useTranslation();

    const handleSave = useCallback(async (values: FormData) => {
        console.log(values);
        if (existingUserId) {
            try {
                await updateUser({ userId: existingUserId, userUpdateCommand: values }).unwrap();
                for (const contact of values.contacts) {
                    if (contact.id) {
                        await updateContact({
                            userId: existingUserId,
                            contactId: contact.id,
                            userUpdateContactCommand: contact
                        }).unwrap();
                    } else {
                        await addUserContact({ userId: existingUserId, userAddContactCommand: contact }).unwrap();
                    }
                }
                for (const contactId of values.contactsToDelete ?? []) {
                    await deleteContact({ userId: existingUserId, contactId }).unwrap();
                }
                enqueueSnackbar(t('users.createEdit.updateSuccess'), { variant: "success" });
                onClose();
            } catch (e) {
                enqueueSnackbar(t('users.createEdit.updateFail'), { variant: "error" });
            }
        } else {
            try {
                const user = await createUser({ userCreateCommand: values }).unwrap();
                for (const contact of values.contacts) {
                    await addUserContact({ userId: user.id, userAddContactCommand: contact }).unwrap();
                }
                // TODO move password display
                enqueueSnackbar(t('users.createEdit.createSuccess') + ` Password: ${user.password}`, { variant: "success", autoHideDuration: 30000 });
                onClose();
            } catch (e) {
                enqueueSnackbar(t('users.createEdit.createFail'), { variant: "error" });
            }
        }
    }, [createUser, enqueueSnackbar, existingUserId, onClose, updateContact, t]);

    useEffect(() => {
        if (existingUserId) {
            if (data) {
                methods.reset({
                    ...data,
                    roleId: data.roles?.[0].id ?? "",
                });
            }
        } else {
            methods.reset(defaultValues);
        }
    }, [data, existingUserId, methods.reset]);

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
            <Dialog open={open} onClose={onClose} maxWidth={"lg"} fullWidth>
                <DialogTitle>
                    {existingUserId ? t('users.createEdit.editTitle') : t('users.createEdit.createTitle')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"email"} label={t('users.createEdit.email')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"username"} label={t('users.createEdit.username')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"firstName"} label={t('users.createEdit.firstName')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name={"lastName"} label={t('users.createEdit.lastName')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect name={"roleId"} label={t('users.createEdit.role')}>
                                {roles?.map(role => (
                                    <MenuItem key={role.id} value={role.id}>{t(`roles.${role.name.toLowerCase()}`)}</MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect name={"organizationId"} label={t('users.createEdit.organization')}>
                                {organizations?.map(organization => (
                                    <MenuItem key={organization.id} value={organization.id}>{organization.name}</MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RHFSwitch name={"isEnabled"} label={t('users.createEdit.enabled')} />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                {fields.map((field, index) => (
                                    <Stack spacing={2} direction={"row"} key={field.id} alignItems={"center"}>
                                        <Grid item md={5}>
                                            <RHFTextField name={`contacts.${index}.phone`} label={t('users.createEdit.phone')} />
                                        </Grid>
                                        <Grid item md={5}>
                                            <RHFTextField name={`contacts.${index}.address`} label={t('users.createEdit.address')} />
                                        </Grid>
                                        <Grid item md={2}>
                                            <IconButton onClick={() => {
                                                if (field.id) {
                                                    methods.setValue(`contactsToDelete.${index}`, field.id);
                                                }
                                                remove(index);
                                            }}>
                                                <Iconify icon={"mdi:close"} />
                                            </IconButton>
                                        </Grid>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Button onClick={() => append({
                                id: null,
                                phone: "",
                                address: "",
                            })}>{t('users.createEdit.addContact')}</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('common.cancel')}</Button>
                    <Button
                        onClick={methods.handleSubmit((data) => handleSave(data), (data) => console.log("invalid", data))}>{t('common.save')}</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
