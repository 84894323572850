import {useGetApiRolesGetMyQuery} from "../services/klaraApi";
import checkBaseRole from "../components/component-guard/checkBaseRole";

export const useRole = () => {
    const {data} = useGetApiRolesGetMyQuery();

    const isAdmin = checkBaseRole("Admin", data ?? []);
    const isKlaraManager = checkBaseRole("KlaraManager", data ?? []);
    const isTeacher = checkBaseRole("Teacher", data ?? []);
    const isStudent = checkBaseRole("Student", data ?? []);

    return {isAdmin, isKlaraManager, isTeacher, isStudent};
}
