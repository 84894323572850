import {
    useDeleteApiGardenByGardenIdUsersMutation,
    useGetApiGardenByGardenIdQuery,
    useGetApiGardenByGardenIdUsersQuery,
    useGetApiOrganizationByOrganizationIdUsersQuery,
    usePostApiGardenByGardenIdUsersMutation
} from "../../services/klaraApi";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DialogTitle, Grid, IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import Iconify from "../../components/Iconify";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../store";
import moment from "moment";
import {useSnackbar} from "notistack";

type GardenUsersProps = {
    gardenId: string;
    organizationId: string;
}


export const GardenUsers = ({gardenId, organizationId}: GardenUsersProps) => {
    const {enqueueSnackbar} = useSnackbar();
    const {data: gardenData} = useGetApiGardenByGardenIdQuery({gardenId});
    const {
        data: gardenAccesses,
        isLoading: gardenAccessesLoading,
        refetch: refetchGardenAccesses
    } = useGetApiGardenByGardenIdUsersQuery({gardenId});
    const {
        data: organizationUsers,
        isLoading: organizationUsersLoading,
        refetch: refetchOrganizationUsers
    } = useGetApiOrganizationByOrganizationIdUsersQuery({organizationId});

    const [addMutation] = usePostApiGardenByGardenIdUsersMutation();
    const [removeMutation] = useDeleteApiGardenByGardenIdUsersMutation();

    const userId = useAppSelector(state => state.user.userId);

    const [search, setSearch] = useState('');

    const {noAccessRows, accessRows} = useMemo(() => {
        const gardenAccessesIdsSet = new Set(gardenAccesses?.map(gardenAccess => gardenAccess.userId));
        const filteredOrganizationUsers = organizationUsers?.filter(organizationUser => !gardenAccessesIdsSet.has(organizationUser.id) && (search === '' || organizationUser.firstName.toLowerCase().includes(search.toLowerCase()) || organizationUser.lastName.toLowerCase().includes(search.toLowerCase())));

        return {
            noAccessRows: filteredOrganizationUsers ?? [],
            accessRows: gardenAccesses?.map(ga => ga.user).filter(u => u && (search === '' || u.firstName.toLowerCase().includes(search.toLowerCase()) || u.lastName.toLowerCase().includes(search.toLowerCase()))) ?? []
        }
    }, [gardenAccesses, organizationUsers, search]);

    const {t} = useTranslation();

    const [selectedToAdd, setSelectedToAdd] = useState<string[]>([]);
    const [selectedToRemove, setSelectedToRemove] = useState<string[]>([]);

    const handleAddAccess = useCallback(async () => {
        try {
            await addMutation({
                gardenId,
                addUsersAccessCommand: {
                    userIds: selectedToAdd
                }
            }).unwrap();

            enqueueSnackbar(t('schools.students.added').toString(), {variant: 'success'});
        } catch (e: any) {
            enqueueSnackbar(e.message, {variant: 'error'});
        } finally {
            refetchGardenAccesses();
            refetchOrganizationUsers();
        }
    }, [addMutation, gardenId, selectedToAdd, refetchGardenAccesses, refetchOrganizationUsers, t]);
    const handleRemoveAccess = useCallback(async () => {
        try {
            await removeMutation({
                gardenId,
                removeUsersAccessCommand: {
                    userIds: selectedToRemove
                }
            }).unwrap();

            enqueueSnackbar(t('schools.students.removed').toString(), {variant: 'success'});
        } catch (e: any) {
            enqueueSnackbar(e.message, {variant: 'error'});
        } finally {
            refetchGardenAccesses();
            refetchOrganizationUsers();
        }
    }, [removeMutation, gardenId, selectedToRemove, refetchGardenAccesses, refetchOrganizationUsers, t]);


    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'firstName',
            headerName: t('schools.students.firstName').toString(),
            width: 160,
        },
        {
            field: 'lastName',
            headerName: t('schools.students.lastName').toString(),
            width: 160,
        },
        {
            field: 'joinedAt',
            headerName: t('schools.students.joined').toString(),
            type: 'number',
            renderCell: (params) => moment.utc(params.value as number).local().format('LL'),
            width: 160,
        }
    ], [t]);

    return (
        <>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={t('schools.students.search')}
                            variant="outlined"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Iconify icon={"mdi:magnify"}/>
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid item xs={5} md={5}>
                        <DialogTitle>
                            {t("garden.userAccess.noAccess")}
                        </DialogTitle>

                        <Box sx={{height: 520, width: '100%'}}>
                            
                            <DataGrid
                                rows={noAccessRows}
                                columns={columns}
                                checkboxSelection
                                loading={gardenAccessesLoading || organizationUsersLoading}
                                rowSelectionModel={selectedToAdd}
                                onRowSelectionModelChange={(rows) => setSelectedToAdd(rows as string[])}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={2} md={2}>
                        <Stack direction="column" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                            <IconButton onClick={handleAddAccess}>
                                <Iconify icon={"mdi:arrow-right-bold"}/>
                            </IconButton>
                            <IconButton onClick={handleRemoveAccess}>
                                <Iconify icon={"mdi:arrow-left-bold"}/>
                            </IconButton>
                        </Stack>
                    </Grid>

                    <Grid item xs={5} md={5}>
                        <DialogTitle>
                            {t("garden.userAccess.haveAccess")}
                        </DialogTitle>

                        <Box sx={{height: 520, width: '100%'}}>
    
                            <DataGrid
                                rows={accessRows}
                                columns={columns}
                                checkboxSelection
                                loading={gardenAccessesLoading || organizationUsersLoading}
                                rowSelectionModel={selectedToRemove}
                                onRowSelectionModelChange={(rows) => setSelectedToRemove(rows as string[])}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
}
