import {ReactNode} from 'react';
import {Container} from "@mui/material";
import {LoginForm} from "../sections/auth/LoginForm";
import {useAppSelector} from "../store";

type Props = {
    children: ReactNode;
};

export default function AuthGuard({children}: Props) {
    const isLoggedIn = useAppSelector(state => state.user.isAuthenticated);

    if (!isLoggedIn) {
        return <Container maxWidth={"md"}> <LoginForm/> </Container>
    }

    return <>{children}</>;
}
