import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Image from "../../components/Image";
import { Link } from "react-router-dom";
import LanguagePopover from "../../layout/header/LanguagePopover";

export const DataPrivacy = () => {

    const { t } = useTranslation();

    return (
        <Container maxWidth={"md"}>
            <Stack direction="column" spacing={10} sx={{ m: 5 }}>
                <Image src={"/images/logo@3x.png"} />
                <Container sx={{ alignSelf: 'center' }}>
                    <Stack direction="column" spacing={4}>
                        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <LanguagePopover />
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                {t("misc.dataErasure.title")}
                            </Typography>
                        </Stack>
                        <Typography variant="body1">
                            {t("misc.dataErasure.body")}
                        </Typography>
                        <Stack direction="column">
                            <Typography variant="body1">
                                {t("misc.dataErasure.body2")}
                                <Link
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = t("misc.dataErasure.mailTo");
                                        e.preventDefault();
                                    }}
                                >
                                    <b>
                                        {" info@klara-gardening.com "}
                                    </b>
                                </Link>
                                {t("misc.dataErasure.body3")}
                            </Typography>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {t("misc.dataErasure.warning")}
                            </Typography>
                            <Typography variant="body2">
                                {t("misc.dataErasure.warningText")}
                            </Typography>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </Container>
    )
}
